<template>

    <div class="question-footer">
        <!-- 答题按钮 -->
        <JudgmentButton :info="TestPaper"></JudgmentButton>
        <div class="score">当前得分：{{ TestPaper.score }}分</div>
    </div>

</template>

<script>
import JudgmentButton from "@/components/MusicTheoryComponents/components/JudgmentButton.vue";

export default {
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            TestPaper: null,
        }
    },
    components: {
        JudgmentButton
    }
}
</script>

<style lang="less" scoped>
.question-footer {
    width: 400px;
}



</style>