<template>
    <div class="track-box">
        <div class="track-info">

            <t-comment style="color: #ffffff;" avatar="" :author="audioInfo.name"
                :content="currentTime + '/' + duration">

                <template #avatar>
                    <t-icon class="audio-player-icon" :size="isReady ? '64' : '48'" color="#ffffff"
                        :name="isReady ? (isPlaying ? 'pause' : 'play') : 'loading'" @click="playAudio"></t-icon>
                </template>

            </t-comment>
        </div>
        <div id="waveform" class="waveform"></div>
    </div>
</template>


<script>
/* eslint-disable */
import { audioClock } from "@/tools/tool"
import { makeWavesAudio } from "@/tools/modules/MusicTheoryModules/_wavesurfer"

export default {
    name: 'trackBoxPage',
    props: {
        audioInfo: {
            type: Object,
            value: {}
        }
    },
    watch: {
        'audioInfo': {
            handler: function (info) {
                if (info.url) {
                    makeWavesAudio('waveform', info.url, this.handler)
                }
            },
            deep: true
        },
    },
    data() {
        return {
            wavesurfer: null,
            isPlaying: false,
            duration: '00:00:00',
            currentTime: '00:00:00',
            isReady: false
        }
    },
    methods: {
        playAudio() {
            this.isPlaying = !this.wavesurfer.isPlaying()

            if (this.isPlaying) {
                this.wavesurfer.play()
            } else {
                this.wavesurfer.pause()
            }
        },
        handler(wavesurfer) {
            this.wavesurfer = wavesurfer;
            this.isPlaying = this.wavesurfer.isPlaying()

            wavesurfer.on('timeupdate', (currentTime) => {
                this.currentTime = audioClock(currentTime)
            })

            wavesurfer.on('loading', (percent) => {
                this.percent = percent
            })

            wavesurfer.on('ready', () => {

                this.isReady = true
                this.duration = audioClock(this.wavesurfer.getDuration())

            })

            wavesurfer.on('finish', () => {
                this.isPlaying = false
            })

            this.$emit('return', this.wavesurfer)
        }
    }
}
</script>

<style lang="less" scoped>
@import url('/src/style//root.less');

.track-box {
    width: 100%;
    height: 128px;
    display: flex;
    border-top: 1px solid #ffffff24;
    border-bottom: 1px solid #ffffff24;
    box-sizing: border-box;
    background-color: #19191944;

    .track-info {
        width: 300px;
        height: 128px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #191919;
        padding: 20px;
    }

    .waveform {
        width: 90vw;
    }
}
</style>