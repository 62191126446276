// store/index.js
import { defineStore } from 'pinia'
import { baseUrl } from '@/tools/tool'
const regex = /\d+/g; // 数字筛选的正则表达式

export const soundPlayer = defineStore('soundPlayer', { // 用户信息
  state: () => ({
    speech: null,
    audioContext: null,
    isAccess: true,
    audioUrl: '',
    isOpenAudioPlayer: false
  }),
  getters: {},
  actions: {
    stopSound() {
      window.speechSynthesis.cancel()

      if(this.audioContext && this.audioContext.state == 'running') {
        this.audioContext.close()
        this.isAccess = false
      }

    },
    readText(text, handler) { // 文字阅读器, 参数1：string，要阅读的文字; 参数2: 阅读结束后要执行的函数
      // 创建一个新的SpeechSynthesisUtterance实例
      const speech = new SpeechSynthesisUtterance(text);
      speech.pitch = 0.1; // 降低音调
      speech.volume = 0.2; // 设置音量
      speech.rate = 1.2;

      window.speechSynthesis.speak(speech);

      if (typeof handler == 'function') {
        speech.onend = () => {
          setTimeout(() => {
            handler()
          }, 1);
        };
      }
    },
    /*
      根据音符名称播放音符，可同时播放多个音符；
      参数1: Array，音符名称组成的数组；
      参数2:回调函数，返回是否正在播放isPlaying
    */
    async makeHarmonyPlay(notes, handler) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
      for (let note of notes) {
        
        const n = note.match(regex)
        note = note.replace(n, Number(n) - 1)

        if(!this.isAccess){
          this.audioContext.close()
          return
        }
        const source = this.audioContext.createBufferSource();
        source.buffer = await fetch(`${baseUrl}/assits/basicMusicKnowledge/piano/${note.replace('#', 's')}.wav`)
          .then((response) => response.arrayBuffer())
          .then((buffer) => this.audioContext.decodeAudioData(buffer));

        source.connect(this.audioContext.destination);

        handler(true); // 通知正在播放的音符

        source.start(0);

        source.onended = () => {
          handler(false); // 通知音符播放结束
        };

      }
    },
    /*
      根据音符名称播放音符，可连续播放多个音符；
      参数1: Array，音符名称组成的数组；
      参数2:回调函数，返回是否正在播放isPlaying
    */
    async makeNotePlay(notes, handler) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();

      for (let note of notes) {

        const n = note.match(regex)
        note = note.replace(n, Number(n) - 1)

        if(!this.isAccess){
          this.audioContext.close()
          return
        }

        const source = this.audioContext.createBufferSource();
    
        source.buffer = await fetch(`${baseUrl}/assits/basicMusicKnowledge/piano/${note.replace('#', 's')}.wav`)
          .then((response) => response.arrayBuffer())
          .then((buffer) => this.audioContext.decodeAudioData(buffer));

        source.connect(this.audioContext.destination);

        handler(true); // 通知正在播放的音符

        source.start(0);

        await new Promise((resolve) => { // 有这一条的话，音符就得播放完毕才能播下一个音符

          source.onended = () => {

            handler(false); // 通知音符播放结束
            resolve();

          };

        });
      }
    },
    async playKick(handler) {
      if(!this.isAccess){
        this.audioContext.close()
        return
      }
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
      const primaryGainControl = this.audioContext.createGain();
      const oscillator = this.audioContext.createOscillator();
      oscillator.frequency.setValueAtTime(440, 0);
      oscillator.frequency.exponentialRampToValueAtTime(0.001, this.audioContext.currentTime + 0.2);

      primaryGainControl.gain.setValueAtTime(0.6, 0);
      primaryGainControl.gain.exponentialRampToValueAtTime(0.001, this.audioContext.currentTime + 0.2);
    
      oscillator.connect(primaryGainControl);
      primaryGainControl.connect(this.audioContext.destination)
      oscillator.start();
  
      handler(true)
  
      oscillator.stop(this.audioContext.currentTime + 0.2);
  
      await new Promise((resolve) => { // 依次播放的关键语句
        oscillator.onended = () => {
          handler(false);
          resolve();
        };
      });
    },
    async makeAudioPlay(audio, handler) {  
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createBufferSource();
      source.buffer = await fetch(baseUrl + '/' + audio)
        .then((response) => response.arrayBuffer())
        .then((buffer) => audioContext.decodeAudioData(buffer));
    
      source.connect(audioContext.destination);
    
      handler(source); // 通知正在播放的音符
    
      source.start(0);
      
    },
    
  }
})

