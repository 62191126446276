<template>

    <div class="knowledge-base-box">

        <div class="image-area">
            <t-image
                @click="getImage" 
                class="image "
                src="https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/knowledge/NoteIntervalKnowledge.png"
                fit="cover"></t-image>
        </div>

        <div class="text-area">

            基础音程的识别通常涉及对音程度数的理解，但在某些情况下，中央音乐学院初级音基可以通过一些简化的方法来识别常见的音程，而不必深入考虑大小度。以下是一些基本的音程识别技巧：
            <br><br>
            一度：任何两个相同名字的音符组成的音程是一度，例如C到C、A到A。
            <br><br>
            二度：在键盘上相邻的白键，自然代表二度音程如DO-RE，SOL-LA。
            <br><br>
            三度：DO-MI或RE-FA等中间隔着一个白键音符。
            <br><br>
            四度：通常需要识别的是SOL到DO或DO到FA这样的跳跃，它们在键盘上相隔两个白键。
            <br><br>
            五度：从低LA到高MI或者低SOL到高SI的音程，在键盘上相隔三个白键。
            <br><br>
            其他的依次类推。
            <br><br>
            八度：任何两个同名且一个在高八度区的音符组成的音程是八度，例如C到高C。
            <br><br>
            这些技巧可以帮助初学者快速识别基础音程，但是随着乐理知识的深入，还是需要学习更精确的音程识别方法，包括理解度数和音数的关系。
            <br><br>
        </div>

        
    </div>
    <t-image-viewer
      v-model:visible="isOpenImgPreViewer"
      :images="[PreImgSrc]"
      @close="isOpenImgPreViewer=false"
    >
    </t-image-viewer>

</template>

<script>
export default {
    data(){
        return {
            PreImgSrc: '',
            isOpenImgPreViewer: false
        }
    },
    methods: {
        getImage(e){
            this.PreImgSrc = e.srcElement.currentSrc;
            this.isOpenImgPreViewer = true
        }
    }
}
</script>

<style lang="less" scoped>
@import url(./style/knowledgeBase.less);
</style>