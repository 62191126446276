<template>
  <t-layout class="app-container">

    <Asider></Asider>

    <t-layout class="app-content">
      <MusicTheoryHeader></MusicTheoryHeader>

      <t-content class="app-foundation-router"><router-view></router-view></t-content>

    </t-layout>
  </t-layout>
</template>


<script>
import MusicTheoryHeader from '@/components/MusicTheoryComponents/musicTheoryHeader'
import Asider from "@/components/MusicTheoryComponents/asider.vue";
export default {
  name: "musicFoundationPage",
  components: {
    MusicTheoryHeader,
    Asider,
  }
}
</script>

<style lang="less">
@import "/src/style/root.less";
@import "/src/style//music_theory.less";

.t-layout {
  background: var(--background-color) !important;
}

.app-container {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .app-content {
    .app-foundation-router {
      padding: var(--pc-padding);
      box-sizing: border-box !important;
      overflow-y: scroll;
    }
  }

}
</style>