<template>
    <div class="track">
        <div class="track-machining">
            <UploadFile :auto="false"></UploadFile>
            <t-button class="audio-process" style="margin-left: 16px;">
                清除底噪和混响
                <template #icon>
                    <t-icon name="sensors"></t-icon>
                </template>
            </t-button>
            <t-button class="audio-process" @click="getAudioDetail">
                音频信息
                <template #icon>
                    <t-icon name="calculator"></t-icon>
                </template>
            </t-button>
            <t-button class="audio-process">
                导出
                <template #icon>
                    <t-icon name="download"></t-icon>
                </template>
            </t-button>
        </div>
        <div class="track-factory">
            <template v-if="audioInfo != null">
                <TrackBox :audioInfo="audioInfo" @return="getWavesurfer"></TrackBox>
            </template>
        </div>
    </div>

    <t-dialog header="音频信息" v-model:visible="isOpenAudioDetail" :cancelBtn="null" :confirmBtn="null" @close="isOpenAudioDetail=false">
      <p v-if="audioDetail">音频时长：{{ audioDetail.duration.toFixed(2) }} 秒<br>采样频率：{{ audioDetail.sampleRate }}<br>声道数量：{{ audioDetail.numberOfChannels }}<br>采样总长度：{{ audioDetail.length }}</p>
    </t-dialog>

</template>

<script>
/* eslint-disable */
import TrackBox from './TrackBox.vue';
import { baseUrl } from '@/tools/tool';
import UploadFile from '@/components/UploadFile.vue';

export default {
    name: 'TechnologyPage',
    data() {
        return {
            audioInfo: {},
            wavesurfer: null,
            audioDetail: null,
            isOpenAudioDetail: false
        }
    },
    mounted() {
        this.getAudioInfo()
    },
    components: {
        TrackBox,
        UploadFile
    },
    methods: {
        getAudioInfo() {
            const url = localStorage.getItem('voiceAudioUrl')
            this.audioInfo = {
                url: baseUrl + '/web/audio/' + url,
                name: url.split('.')[0]
            }
        },
        getWavesurfer(wavesurfer) {
            this.wavesurfer = wavesurfer
        },
        getAudioDetail() {
            if (this.wavesurfer) {
                this.audioDetail = this.wavesurfer.getDecodedData()
                this.isOpenAudioDetail = true
            }
        }
    }
}
</script>

<style lang="less" scoped>
.track {
    width: 100%;

    .track-machining {
        width: 100%;
        height: 40px;
        padding-left: 280px;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .audio-process {
            color: #f6f6f6dd;
            margin-right: 16px;
            background-color: #38335144;
            border: none;
            --ripple-color: var(--theme-color);
        }

    }

    .track-factory {
        width: 100%;

    }
}
</style>