<template>
    <TestPitch :info="info"></TestPitch>
</template>


<script>
import TestPitch from '@/components/MusicTheoryComponents/TestPitch.vue';
import { NoteRecognition } from "@/tools/MusicTheoryTools";

export default {
    components: {
        TestPitch
    },
    data(){
        return {
            info: new NoteRecognition('stave-paper', 'NotePitchTwelveEqualLawName', 'name')
        }
    }
}
</script>
