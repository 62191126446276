<template>
    <div id="musc-theory-test-content-box">
        <div class="music-theory-test-question">
            <div class="question-box">
          
                <QuestionIntro :info="TestPaper"></QuestionIntro>

                <div class="question-content">

                    <div class="question-tigan"> {{ TestPaper.question_num + "，" + TestPaper.questionIntro.questionTitle }}  </div>

                    <ListeningAudioBox :info="TestPaper"></ListeningAudioBox>

                    <StaveOptionsCard :info="TestPaper"></StaveOptionsCard>

                </div>

                <div class="question-footer">
                   <!-- 答题按钮 -->
                   <JudgmentButton :info="TestPaper"></JudgmentButton>

                    <div class="score">当前得分：{{ TestPaper.score }}分</div>
                </div>
            </div>
        </div>

        <div class="tips">
            <t-icon v-if="!isOpenTips" name="help-circle-filled" size="24" color="rgb(130,23,90)" @click="isOpenTips = true" style="cursor: pointer;"></t-icon>
            <KnowledgeBase :info="TestPaper.page" :isOpen="isOpenTips" @close="isOpenTips = false" :showOverlay="false"></KnowledgeBase>
        </div>

    </div>
</template>

<script>
/* eslint-disable */
import StaveOptionsCard from "@/components/MusicTheoryComponents/components/StaveOptionsCard.vue";
import JudgmentButton from "@/components/MusicTheoryComponents/components/JudgmentButton.vue";
import  QuestionIntro from '@/components/MusicTheoryComponents/components/QuestionIntro'
import ListeningAudioBox from '@/components/MusicTheoryComponents/components/ListeningAudioBox'
import KnowledgeBase from '@/components/MusicTheoryComponents/KnowledgeBox/KnowledgeBase.vue';
import { doesNotContainAny } from "@/tools/modules/MusicTheoryModules/_assits_help";

export default {
    name: "questionsPage",
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    components: {
        JudgmentButton,
        QuestionIntro,
        ListeningAudioBox,
        StaveOptionsCard,
        KnowledgeBase
    },
    data() {
        return {
            TestPaper: null,
            isOpenTips: true
        };
    },
    mounted() {
        this.TestPaper.InitQuestion();
    },
};
</script>

<style lang="less" scoped>
@import url('/src/style/root.less');
@import url('/src/style/music_theory.less');

</style>