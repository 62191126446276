/* eslint-disable */
import { soundPlayer } from "@/store/soundplayer";

/*
  根据音符名称播放音符，可连续播放多个音符；
  参数1: Array，音符名称组成的数组；
  参数2:回调函数，返回是否正在播放isPlaying
*/

export const makeNotePlay = async (notes, handler) => { 
  soundPlayer().isAccess = true // 专门监听是否可以播放的
  soundPlayer().makeNotePlay(notes, handler)
};

/*
  根据音符名称播放音符，可同时播放多个音符；
  参数1: Array，音符名称组成的数组；
  参数2:回调函数，返回是否正在播放isPlaying
*/
export const makeHarmonyPlay = async (notes, handler) => { 
  soundPlayer().isAccess = true
  soundPlayer().makeHarmonyPlay(notes, handler)
};
// 根据音符时值播放音符序列
export const playTempo = (tempoNames, instrument, handler) => { // 参数1：节奏音符盒子
  soundPlayer().isAccess = true
  let melody = tempoNames.map(item => item.map(j => j.keys).join(',')).join(',').split(',')
  let tempoTimeBox = tempoNames.map(item => item.map(j => j.duration).join(',')).join(',').split(',')
  
  tempoTimeBox = tempoTimeBox.map(item => {
    const speed = 3600
    if (item.indexOf('d') !== -1) {
      return speed / Number(item.replace('d', '')) * Number(item.replace('d', '')) / Number(item.replace('d', '')) * (1 + 0.5)
    } else if (item.indexOf('r') !== -1) {
      return speed / Number(item.replace('r', ''))
    } else {
      return speed / Number(item)
    }
  })

  for (let i = 1; i < tempoTimeBox.length; i++) {
    tempoTimeBox[i] = tempoTimeBox[i - 1] + tempoTimeBox[i]
  }

  tempoTimeBox.unshift(0)
  tempoTimeBox.pop()
  tempoTimeBox.forEach((item, index) => {
    if(!soundPlayer().isAccess){
      return
    }
    setTimeout(() => {
      if(instrument == 'piano'){
        let note = melody[index].split('/').join('')
        soundPlayer().makeHarmonyPlay([note], handler)
      }else if(instrument == 'kick'){
        soundPlayer().playKick(handler)
      }
    }, tempoTimeBox[index])

  })
}

export const readText = (text, handler) => { // 文字阅读器, 参数1：string，要阅读的文字; 参数2: 阅读结束后要执行的函数
  soundPlayer().isAccess = true
  soundPlayer().readText(text, handler)
}

