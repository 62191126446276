<template>
    <div id="cash-desk">
        <t-list>
            <t-list-item>
                <t-list-item-meta split :image="orderinfo.primaryImage" :title="'订单编号：' + orderinfo.order_id">
                    <template #description>
                        <div class="description">
                            订单详情：{{ orderinfo.title }}
                        </div>
                        <div class="price">
                            <span style="font-size: 18px;">¥</span>
                            <span style="font-size: 48px; font-weight: 600px;">{{
                                orderinfo.price }}</span>
                            <span style="">元</span>
                        </div>
                    </template>
                </t-list-item-meta>
            </t-list-item>
        </t-list>

        <div class="paymant">
            <t-tabs :default-value="1" theme="card">
                <t-tab-panel :value="1" label="微信支付">
                    <div style="
              width: 600px;
              height: 460px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            ">
                        <t-image src="https://www.duoyinchina.com/assits/images/wechatPay.jpg"
                            style="position: absolute; top: 20px; left: 20px" fit="contain"
                            :style="{ height: '40px' }"></t-image>
                        <div style="display: flex; flex-direction: column; align-items: center">
                            <t-image :src="qrcodeImage" fit="contain"
                                :style="{ width: '300px', height: '300px' }"></t-image>
                            <p>请使用微信扫码支付</p>
                        </div>
                    </div>
                </t-tab-panel>
                <t-tab-panel :value="2" label="支付宝支付">
                    <div style="
              width: 600px;
              height: 460px;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
                        <t-image src="https://www.duoyinchina.com/assits/images/aliPay.jpeg"
                            style="position: absolute; top: 20px; left: 20px" fit="contain"
                            :style="{ height: '40px' }"></t-image>
                        <div style="display: flex; flex-direction: column; align-items: center">
                            <t-image :src="qrcodeImage" fit="contain"
                                :style="{ width: '300px', height: '300px' }"></t-image>
                            <p>请使用支付宝扫码支付</p>
                        </div>
                    </div>
                </t-tab-panel>
            </t-tabs>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import QRCode from "qrcode";
import { logo_src } from "@/tools/MusicTheoryTools";
import { post } from "@/tools/tool";
import io from 'socket.io-client';
import { MessagePlugin } from "tdesign-vue-next";
import { userstore } from '@/store/userinfo';

export default {
    props: {
        orderinfo: {
            type: Object,
            default: {},
        },
    },
    watch: {
        orderinfo: {
            deep: true,
            handler(n) {
                this.requireQRCode(n)
                this.payReaction(n)
            },
            immediate: true
        }
    },
    data() {
        return {
            logo_src: logo_src,
            qrcodeImage: null,
        };
    },
    methods: {
        async requireQRCode(orderinfo) {
            // 参数1: 商品信息
            const data = await post("wxpay/PC/", { orderinfo: orderinfo });
            const codeUrl = JSON.parse(data.split("\n")[1]).code_url;
            QRCode.toDataURL(codeUrl, { errorCorrectionLevel: "H" })
                .then((url) => {
                    this.qrcodeImage = url;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        payReaction(orderinfo) {

            const socket = io('http://182.92.116.239:39004', {
                query: {
                    order_id: orderinfo.order_id  // 使用实际的order_id
                }
            });

            socket.on('connect', (e) => {
                console.log('已成功连接到服务器', e);
            });

            socket.on('wxPcPayReaction', (data) => {
                if(data.order_id == orderinfo.order_id){
                    const tradeStatus = JSON.parse(data.tradeStatus)
                    if(tradeStatus.status == 'SUCCESS'){
                        MessagePlugin.success("支付成功！", 2000);
                        userstore().updateUserInfo();
                        userstore().isOpenOrderDialog = false;
                    }else{
                        MessagePlugin.success("支付失败, 请重新支付！", 2000);
                        userstore().isOpenOrderDialog = false;
                    }

                }
            });


        }
    },
};
</script>

<style lang="less" scoped>
@import url("/src/style/root.less");

#cash-desk {
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;

    .description {
        margin-bottom: 32px;
    }

    .price {
        color: var(--theme-color);
    }
}

.paymant {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
