<template>

    <t-radio-group class="answer-box" :value="TestPaper.answer" @change="answerChange">

        <t-radio class="answer-box-radio" placement="right" v-for="(item, index) in TestPaper.answerBox" :key="index"
            :value="item.index" borderless>

            <template #label>
                <p class="answer-box-radio-p">{{ item.label }}</p>
            </template>

        </t-radio>

    </t-radio-group>

</template>

<script>
export default {
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            TestPaper: null,
        }
    },
    methods: {
        answerChange(e) {
            this.TestPaper.answer = e;
        },
    }
}
</script>
<style lang="less" scoped>
@import url('/src/style/music_theory.less');

.answer-box {
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .answer-box-radio {
        .answer-box-radio-p {
            width: auto;
            font-size: var(--pc-font-size-normal) !important;
        }
    }
}
</style>