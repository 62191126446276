<template>

    <div class="knowledge-base-box">
        
        <div class="text-area">

        识别五线谱上的音高，需要了解五线谱的基本构成和音符的位置。
        <br><br>
        五线谱由五条平行线组成，这些线从下往上依次被称为第一线、第二线、第三线、第四线和第五线。
        <br><br>
        五条线之间的空间则称为“间”，同样从下往上数为第一间、第二间、第三间和第四间。

        </div>
        
        <div class="image-area">
            <t-image
                @click="getImage" 
                class="image"
                src="https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/knowledge/NotePitchSingKnowledge.png"
                fit="cover"></t-image>
        </div>

        <div class="text-area">
            唱名是音乐术语，在演唱旋律时为方便唱谱而采用的名称，通常指Do、Re、Mi、Fa、Sol、La、Si这七个音阶名称。
            <br><br>
            音乐中的唱名系统是为了帮助人们更易于学习和演唱音乐而设计的。它包括以下内容：
            <br><br>
            首调唱名法：这种方法将任何给定的音阶的第一个音称为“Do”，之后按照音阶顺序依次命名剩下的六个音。例如，在一个C大调音阶中，C是“Do”，D是“Re”，以此类推，直到G是“Sol”，下一个A再次回到“La”。
            <br><br>
            固定唱名法：这种方法则将特定的音名始终对应到相同的唱名上。无论音阶如何变化，C音总是被唱作“Do”，D音总是“Re”，等等。
           
        </div>

    </div>
    <t-image-viewer
      v-model:visible="isOpenImgPreViewer"
      :images="[PreImgSrc]"
      @close="isOpenImgPreViewer=false"
    >
    </t-image-viewer>

</template>

<script>
export default {
    data(){
        return {
            PreImgSrc: '',
            isOpenImgPreViewer: false
        }
    },
    methods: {
        getImage(e){
            this.PreImgSrc = e.srcElement.currentSrc;
            this.isOpenImgPreViewer = true
        }
    }
}
</script>

<style lang="less" scoped>
@import url(./style/knowledgeBase.less);
</style>