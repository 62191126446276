<template>
    <TestPitch :info="info"></TestPitch>
</template>


<script>
import TestPitch from '@/components/MusicTheoryComponents/TestPitch.vue';
import { StaveMode } from '@/tools/MusicTheoryTools';

export default {
    components: {
        TestPitch
    },
    data(){
        return {
            info: new StaveMode('stave-paper', 'TonicSolfa')
        }
    }
}
</script>
