/* eslint-disable */
import { TestAssets } from "./assits";

export class ListeningRhythmFirst {

  constructor(div, page) {
    TestAssets.questionInfoInit( this, div, page )
    this.isRandom = false;
    this.tempoMusics = [];
    this.instrument='kick';
  }

  InitQuestion() {
    let isSenior = false;

    if(this.page == 'ListeningRhythmPitchPrimary'){
      this.isRandom = true;
      this.instrument = 'piano'
      
    }else if(this.page == 'ListeningRhythmPitchSenior'){
      this.isRandom = true;
      this.instrument = 'piano'
      isSenior = true
    }else{
      this.instrument='kick';
    }

    this.questionIntros = TestAssets.PrimaryQuestionIntros(this.page, isSenior);

    this.questionIntro = this.questionIntros.find(
      (item) => item.value == this.level
    );

    this.notesBox = this.questionIntro.notesBox; // 当前级别需要的音符数组

    this.tempoMusics = []

    TestAssets.InitVariate(this)
    this.makeAnswerBox(); // 根据随机音符生成答题选项卡

  }
  makeAnswerBox() {

    const indexBox = ["A", "B", "C", "D"]; // 选项卡

    this.tempoMusics = TestAssets.generate_unique_rhythms(this) // 生成4组随机并不重复的节奏乐谱

    this.answerIndex = Math.floor(Math.random() * this.tempoMusics.length) // 选择一组当答案

    let answerBox = [];

    for (let i = 0; i < this.tempoMusics.length; i++) { // 选项卡属性确定，index是ABCD选项，value是音符或是答案，label是序号，往往和index一样，isCorrect选项是是否是正确答案
      answerBox.push({
        index: indexBox[i],
        value: indexBox[i],
        label: indexBox[i],
        isCorrect: this.answerIndex == i,
      });
    }

    const div = this.div

    this.answerBox = TestAssets.get8notesfromArray(answerBox, 5)

    setTimeout(() => { // 打印音符
      for (let i = 0; i < 4; i++) {
        TestAssets.NoteTempoPrinter(div[i], this.tempoMusics[i], ''); // 打印随机生成的音符，和提问用的速度术语
      }
    }, 0);
  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
  }
  rateChange(e) { // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this)
  }
  makeNotePlay() { // 播放节奏音频
    const tempoNames = this.tempoMusics[this.answerIndex] // 生成随机节奏音符

    console.log(tempoNames)

    const handler = (isPlaying)=>{
      this.isPlaying = isPlaying
    }

    TestAssets.playTempo(tempoNames, this.instrument, handler)
  }

}