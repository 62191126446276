import WaveSurfer from 'wavesurfer.js';

export const makeWavesAudio = (div, url, handler) => { // 将音频的波形打印到div上，并进行控制播放；参数1:div的id；参数2: 音频地址url
  const wavesurfer = WaveSurfer.create({
    container: '#' + div,
    waveColor: 'violet',
    progressColor: 'purple',
    url: url
  })

  if (handler) {
    handler(wavesurfer)
  }

  // 监听->点击音频不同位置时
  wavesurfer.on('interaction', () => {
    wavesurfer.play()
    handler(wavesurfer)
  })
}
