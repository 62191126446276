<template>

    <t-button 
        v-if="TestPaper" 
        size="large" 
        :style="{
        color: TestPaper.result == 'right' ? 'green' : (TestPaper.result == 'wrong' ? 'red' : ''),
        borderColor: TestPaper.result == 'right' ? 'green' : (TestPaper.result == 'wrong' ? 'red' : '')
        }"
        class="next-question-button" @click="judgmente" variant="outline"
    >
        下一题

        <template #suffix>
            <t-icon class="icon" v-if="TestPaper.result == 'right'" name="check-circle-filled"
                style="color: green;"></t-icon>
            <t-icon class="icon" v-else-if="TestPaper.result == 'wrong'" name="close-circle-filled"
                style="color: red;"></t-icon>
            <t-icon class="icon" v-else name="arrow-right"
                style="font-weight: 200; color: rgba(0, 0, 0, 0.7);"></t-icon>
        </template>

    </t-button>

</template>

<script>
export default {
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            TestPaper: null,
        }
    },
    methods: {
        judgmente() {
            this.TestPaper.judgmente();
        }
    }
}
</script>
<style lang="less">
.next-question-button {
    border-radius: 5px !important;
}

.next-question-button:hover {
    border: 1px solid #000000;
    color: #000000;
    font-size: 400;
}
</style>