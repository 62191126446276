<template>
  <t-upload v-model="files" :auto-upload="true" theme="file" :abridge-name="[10, 8]" :format-response="formatResponse"
    :draggable="auto" action="/api/web/audioClear" style="color: #ffffff !important;" :useMockProgress="true" :mockProgressDuration="500"/>

</template>
<script>
/* eslint-disable */
export default {
  props: {
    auto: {
        type: Boolean,
        value: true
    }
  },
  data() {
    return {
      files: []
    }
  },
  methods: {
    formatResponse(res) {
      this.$emit('return', res.url)
      return res;
    }
  }
}

</script>

<style lang="less">
@import url(/src/style/root.less);

.t-upload__trigger>div>span, .t-upload__dragger-progress-info, .t-upload__single-name {
  color: #ffffff !important;
}

.t-upload__dragger:hover {
  border-color: var(--theme-color) !important;

  .t-upload__trigger>div>span {
    color: var(--theme-color) !important;
  }
}
</style>