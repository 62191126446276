/* eslint-disable */
import { userstore } from "@/store/userinfo";
import { soundPlayer } from "@/store/soundplayer.js"
import { Project } from "@/config.js"
import { educationPages } from "./_musicTheoryRouterPages";
import { isTokenOk } from "./token";

export const beforEach = async (to, from, next) => {
    const token = localStorage.getItem("duoyintoken");
    if(token && userstore().userinfo == null){
        await isTokenOk()
    }

    if (to.path.includes('/musicFoundation')) { // 音乐基础知识考试路由控制
        await beforeMusicFoundation(to, next)
    } else {
        next()
    }

}

const beforeMusicFoundation = async (to, next) => { // 音乐基础知识考试收费处理
    const page = educationPages.find(item => {
        if (item.path === to.path && item.isRequiredPaid) {
            return item
        }
    }); // 当前页面是否需要支付

    if (page && page.isRequiredPaid) {
        const tokenInfo = await isTokenOk()

        if (tokenInfo.status == 200) { // 是否登录了
      
            if (userstore().isMusicTheoryMember) { // 已购买 
                next()
            } else { // 未购买
                await userstore().purchaseMusicTheory(page.pro_id)
            }

        } else { // 未登录，拉去登录 
            next('/index/register')
        }

    } else {
        next()
    }
}


