/* eslint-disable */
import { TestAssets } from "./assits";
import { baseUrl, get } from "@/tools/tool"
import {shuffleArray } from "./_assits_help"

export class AreaMusic {
  constructor(div, page, askfor) {
    TestAssets.questionInfoInit( this, div, page, askfor)
  }
  InitQuestion() {
    TestAssets.InitVariate(this)
    this.questionIntros = [this.questionIntros[0]]
    this.questionIntro = this.questionIntros[0]

    this.makeAnswerBox(); // 生成答题卡
  }
  async makeAnswerBox() {
    const indexBox = ["A", "B", "C", "D"];
    
    const optionBox = await getInfoBoxByPage(this)

    const answerItem = optionBox[Math.floor(Math.random() * optionBox.length)]
    
    this.wavesurfer ? this.wavesurfer.destroy() : null
    
    this.isReady = false;
    
    const handler = (wavesurfer)=>{

      this.wavesurfer = wavesurfer;

      this.isPlaying = this.wavesurfer.isPlaying()

      this.wavesurfer.on('ready', ()=>{
        this.isReady = true
      })
    }

    const audioUrl = `${baseUrl}/web/MusicTheory/audio/${this.askfor}/` + answerItem; // 答案的音频地址

    TestAssets.makeWavesAudio('audio', audioUrl, handler)

    let answerBox = [];

    optionBox.forEach((item, i)=>{
      answerBox.push({
        index: indexBox[i],
        value: item[0],
        label: `${indexBox[i]}, ${item.replace(/\d.*/, '')}`,
        isCorrect: answerItem == item,
      });
    })

    this.answerBox = answerBox;
  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this, true)
  }
  audioPlayer(){
    if (this.wavesurfer.isPlaying()) {
      this.wavesurfer.pause()
      this.isPlaying = false
  } else {
      this.wavesurfer.play()
      this.isPlaying = true
  }
  }
}

const getInfoBoxByPage = async (that)=>{ // 向服务端请求4个符号列表和曲谱
  let list = await get('/web/MusicTheory/sheets', 'fileName=' + that.askfor)

  list = shuffleArray(list)

  let setBox = []

  for(let i=0; i<list.length; i ++){
    if(!setBox.some(item=>item.includes(list[i].replace(/\d.*$/, '')))){ // 去掉重复的符号->符号后面写了1，2，3等不一样的曲谱，在答案卡上去重
      setBox.push(list[i])
    }
  }

  return [...setBox.splice(0, 4)]

}