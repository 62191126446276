<template>
    <t-divider :align="align" :style="{marginTop: marginTop}">
        <div>
            <h1 style="margin: 6px 0; text-align: center;">{{title}}</h1>
            <p style="margin: 6px 0; color: #00000099; text-align: center;">{{subTitle}}</p>
        </div>
    </t-divider>
</template>

<script>
    export default {
        props: ['title', 'subTitle', 'marginTop', 'align']
    }



</script>