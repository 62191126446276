<template>
    <div id="musc-theory-test-content-box">
        <div class="music-theory-test-question">
            <div class="question-box">
                
                <QuestionIntro :info="TestPaper"></QuestionIntro>

                <div class="question-content">
                    <div class="question-tigan">
                        {{
                            TestPaper.question_num +
                            "，" +
                            TestPaper.questionIntro.questionTitle
                        }}
                    </div>

                    <div class="audio-waves-box">

                        <div class="audio-info">
                            <div class="audio-backimg">
                                <t-icon class="audio-play" :name="TestPaper.isPlaying ? 'pause-circle' : 'play-circle'"
                                    size="24" @click="makeNotePlay"></t-icon>
                            </div>
                            <div>请听辨</div>
                        </div>
                    </div>

                    <StaveOptionsCard :info="TestPaper"></StaveOptionsCard>
                </div>

                <ScoreBox :info="TestPaper"></ScoreBox>
            </div>
        </div>

        <div class="tips">
            <t-icon v-if="!isOpenTips" name="help-circle-filled" size="24" color="rgb(130,23,90)" @click="isOpenTips = true" style="cursor: pointer;"></t-icon>
            <KnowledgeBase :info="TestPaper.page" :isOpen="isOpenTips" @close="isOpenTips = false" :showOverlay="false"></KnowledgeBase>
        </div>

    </div>
</template>

<script>
/* eslint-disable */
import ScoreBox from '@/components/MusicTheoryComponents/components/ScoreBox.vue';
import StaveOptionsCard from "@/components/MusicTheoryComponents/components/StaveOptionsCard.vue";
import KnowledgeBase from '@/components/MusicTheoryComponents/KnowledgeBox/KnowledgeBase.vue';
import QuestionIntro from "@/components/MusicTheoryComponents/components/QuestionIntro";

export default {
    name: "questionsPage",
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    components: {
        ScoreBox,
        StaveOptionsCard,
        QuestionIntro,
        KnowledgeBase
    },
    data() {
        return {
            TestPaper: null,
            isPlaying: false,
            isOpenTips: true
        };
    },
    mounted() {
        this.TestPaper.InitQuestion();
    },
    methods: {
        makeNotePlay() {
            this.TestPaper.makeNotePlay();
        },
    }
};
</script>

<style lang="less" scoped>
@import url('/src/style/root.less');
@import url('/src/style/music_theory.less');

.audio-waves-box {
    display: flex;
    align-items: center;
    width: 140px;
    justify-content: space-between;
    margin-top: 32px;

    .audio-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .audio-backimg {
            background-image: url("https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/img/piano.png");
            width: 60px;
            height: 60px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .audio-play {
                color: #ffffff;
            }
        }
    }
}

</style>
