<template>
    <TestPitch :info="info"></TestPitch>
    <div></div>
</template>


<script>
/* eslint-disable */

import TestPitch from '@/components/MusicTheoryComponents/TestPitch.vue';
import { QuestionRhythmFirst} from "@/tools/MusicTheoryTools";


export default {
    components: {
        TestPitch
    },
    data() {
        return {
            info: new QuestionRhythmFirst('stave-paper', 'NoteRhythmCount')
        }
    }
}
</script>
