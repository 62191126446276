export const audioClock = (duration) => {
    var minutes = Math.floor(duration / 60);
    var seconds = Math.floor(duration % 60);
    var milliseconds = Math.round((duration % 1) * 100);
    return (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds) + "." + (milliseconds < 10 ? "0" + milliseconds : milliseconds);
}

export const randomAudioId = () => {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < 10; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    return result;
}

