/* eslint-disable */
import { TestAssets } from "./assits";
import { get } from "../request";
import { shuffleArray } from "./_assits_help";

export class BaseExam {
  
  constructor(page, askfor) {
    TestAssets.questionInfoInit(this, '', page, askfor)
  }

  InitQuestion() {
    this.questionIntros = [this.questionIntros[0]]
    this.questionIntro = this.questionIntros[0]
    TestAssets.InitVariate(this)
    this.makeAnswerBox(); // 生成答题卡
  }
  async makeAnswerBox() {
    const indexBox = ["A", "B", "C", "D"];
    const optionBox = await getInfoBoxByPage(this)
    const answerInfo = optionBox[Math.floor(Math.random() * optionBox.length)]
    this.questionSheet = 'https://www.duoyinchina.com/web/MusicTheory/image/' + this.askfor + '/' + answerInfo

    let answerBox = [];

    for (let i = 0; i < optionBox.length; i++) {
      answerBox.push({
        index: indexBox[i],
        value: optionBox[i],
        label: `${indexBox[i]}, ${this.questionIntro.answerTitle} ${optionBox[i].replace(/\d.*$/, '')}`,
        isCorrect: answerInfo == optionBox[i],
      });

    }

    this.answerBox = answerBox;
  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
  }
  rateChange(e) { // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this)
  }
}


const getInfoBoxByPage = async (that)=>{ // 向服务端请求4个符号列表和曲谱
  let list = await get('/web/MusicTheory/sheets', 'fileName=' + that.askfor)

  list = shuffleArray(list)

  let setBox = []

  for(let i=0; i<list.length; i ++){
    if(!setBox.some(item=>item.includes(list[i].replace(/\d.*$/, '')))){ // 去掉重复的符号->符号后面写了1，2，3等不一样的曲谱，在答案卡上去重
      setBox.push(list[i])
    }
  }

  return [...setBox.splice(0, 4)]

}