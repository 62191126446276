<template>
    <div id="musc-theory-test-content-box">

        <div class="music-theory-test-question">

            <div class="question-box">

                <QuestionIntro :info="TestPaper"></QuestionIntro>

                <div class="question-content">

                    <div class="question-tigan">{{ TestPaper.question_num + '，' + TestPaper.questionIntro.questionTitle
                        }}
                    </div>

                    <div class="stave">
                        <t-image :src="TestPaper.questionSheet" fit="contain" @click="getImage" 
                            :style="{ width: '100%', height: '360px', backgroundColor: '#ffffff', cursor: 'pointer' }"></t-image>
                    </div>

                    <FontOptionsCard :info="TestPaper"></FontOptionsCard>

                </div>

                <ScoreBox :info="TestPaper"></ScoreBox>

            </div>

        </div>

        <div class="tips">
            <t-icon v-if="!isOpenTips" name="help-circle-filled" size="24" color="rgb(130,23,90)"
                @click="isOpenTips = true" style="cursor: pointer;"></t-icon>
            <KnowledgeBase :info="TestPaper.page" :isOpen="isOpenTips" @close="isOpenTips = false" :showOverlay="false">
            </KnowledgeBase>
        </div>
    </div>
    <t-image-viewer v-model:visible="isOpenImgPreViewer" :images="[PreImgSrc]" @close="isOpenImgPreViewer = false">
    </t-image-viewer>
</template>


<script>
/* eslint-disable */
import QuestionIntro from '@/components/MusicTheoryComponents/components/QuestionIntro'
import { theme_color } from "@/tools/MusicTheoryTools";
import ScoreBox from '@/components/MusicTheoryComponents/components/ScoreBox.vue';
import KnowledgeBase from '@/components/MusicTheoryComponents/KnowledgeBox/KnowledgeBase.vue';
import FontOptionsCard from "@/components/MusicTheoryComponents/components/FontOptionsCard.vue";

export default {
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    components: {
        ScoreBox,
        KnowledgeBase,
        QuestionIntro,
        FontOptionsCard,
    },
    data() {
        return {
            TestPaper: null,
            theme_color: theme_color,
            isOpenTips: false,
            PreImgSrc: '',
            isOpenImgPreViewer: false
        }
    },
    mounted() {
        this.TestPaper.InitQuestion()
    },
    methods: {
        getImage(e) {
            this.PreImgSrc = e.srcElement.currentSrc;
            this.isOpenImgPreViewer = true
        }
    }
}
</script>

<style lang="less" scoped>
@import url('/src/style/root.less');
@import url('/src/style/music_theory.less');

.stave {
    width: auto;
    height: auto;
    margin: var(--pc-margin) 0;
}
</style>