class Setting {
    constructor(status){ // dev/build
        this.status = status
        this.WxRegisterRedirectUrl = this.getWxRegisterRedirectUrl(status)
        this.baseUrl = this.getBaseUrl(status)
    }

    getWxRegisterRedirectUrl(status){
        return status == 'dev' ? 'https://www.duoyinchina.com/web/wxScaningQrcodeDev' : 'https://www.duoyinchina.com/web/wxScaningQrcode'
    }

    getBaseUrl(status){
        return status == 'dev' ? '/api' : 'https://www.duoyinchina.com'
    }

}

export const Project = new Setting('build')
