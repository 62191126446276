// store/index.js
import { defineStore } from 'pinia'
import { baseUrl } from '@/tools/tool'
import WaveSurfer from 'wavesurfer.js';

export const audioPlayer = defineStore('audioPlayer', { // 用户信息
    state: () => ({
        audioInfo: '',
        isOpenAudioPlayer: false
    }),
    getters: {},
    actions: {
        async makeAudioPlay(audio, handler) {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.createBufferSource();
            source.buffer = await fetch(baseUrl + '/' + audio)
                .then((response) => response.arrayBuffer())
                .then((buffer) => audioContext.decodeAudioData(buffer));

            source.connect(audioContext.destination);

            handler(source); // 通知正在播放的音符

            source.start(0);

        },
        makeWavesAudio(div, url, handler){ 
            const wavesurfer = WaveSurfer.create({
                container: '#' + div,
                waveColor: 'violet',
                progressColor: 'purple',
                url: url,
                width: 340, // 设置宽度
                height: 60 // 设置高度
            })

            if (handler) {
                handler(wavesurfer)
            }

            wavesurfer.on('interaction', () => {
                wavesurfer.play()
                handler(wavesurfer)
            })
        }

    }
})
