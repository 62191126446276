<template>
    <TestListeningRhythm :info="info"></TestListeningRhythm>
</template>


<script>
import TestListeningRhythm from '@/components/MusicTheoryComponents/TestListeningRhythm.vue';
import { ListeningRhythmFirst } from "@/tools/MusicTheoryTools";

export default {
    components: {
        TestListeningRhythm
    },
    data(){
        return {
            info: new ListeningRhythmFirst(
                [
                    "staveA",
                    "staveB",
                    "staveC",
                    "staveD",
                ],
                'ListeningRhythmExercise'
            )
        }
    }
}
</script>
