<template>
    <div id="musc-theory-test-content-box">
        <div class="music-theory-test-question">
            <div class="question-box">

                <QuestionIntro :info="TestPaper"></QuestionIntro>

                <div class="question-content">
                    <div class="question-tigan">
                        {{
                            TestPaper.question_num +
                            "，" +
                            TestPaper.questionIntro.questionTitle
                        }}
                    </div>

                    <div class="stave-box" style="margin-top: 20px; width: auto">
                        <div id="stave" style="background-color: #ffffff;"></div>
                    </div>

                    <StaveOptionsCard :info="TestPaper"></StaveOptionsCard>
                    <ScoreBox :info="TestPaper"></ScoreBox>
                </div>


            </div>
        </div>
        <div class="tips">
            <t-icon v-if="!isOpenTips" name="help-circle-filled" size="24" color="rgb(130,23,90)" @click="isOpenTips = true" style="cursor: pointer;"></t-icon>
            <KnowledgeBase :info="TestPaper.page" :isOpen="isOpenTips" @close="isOpenTips = false" :showOverlay="false"></KnowledgeBase>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { theme_color } from "@/tools/MusicTheoryTools";
import  QuestionIntro from '@/components/MusicTheoryComponents/components/QuestionIntro'
import StaveOptionsCard from "@/components/MusicTheoryComponents/components/StaveOptionsCard.vue";
import ScoreBox from '@/components/MusicTheoryComponents/components/ScoreBox.vue';
import KnowledgeBase from '@/components/MusicTheoryComponents/KnowledgeBox/KnowledgeBase.vue';

export default {
    name: "questionsPage",
    props: ['info'],
    watch: {
        info: {
            handler(n){
                this.TestPaper = n 
            },
            deep: true,
            immediate: true
        }
    },
    components: {
        QuestionIntro,
        StaveOptionsCard,
        ScoreBox,
        KnowledgeBase
    },
    data() {
        return {
            theme_color: theme_color,
            TestPaper: null,
            isOpenTips: false
        };
    },
    mounted() {
        this.TestPaper.InitQuestion();
    },
    methods: {
        answerChange(e) {
            this.TestPaper.answer = e;
        }
    },
};
</script>

<style lang="less" scoped>
@import url('/src/style/root.less');
@import url('/src/style/music_theory.less');

</style>

