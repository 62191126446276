<template>
    <TestListening :info="info"></TestListening>
</template>


<script>
import { ListeningRhythmFirst } from "@/tools/MusicTheoryTools";
import TestListening from '@/components/MusicTheoryComponents/TestListening.vue';

export default {
    components: {
        TestListening
    },
    data(){
        return {
            info: new ListeningRhythmFirst(
                [
                    "staveA",
                    "staveB",
                    "staveC",
                    "staveD",
                ],
                'ListeningRhythmPitchPrimary'
            )
        }
    }
}
</script>
