export const generateOrderNumber = (productId) =>{ // 参数1: 商品id, 返回：生成的订单id； 也可用于生成任何相似的id； 根据时间戳和商品ID和6位随机数，生成商品随机订单
  const now = new Date();
  const year = now.getFullYear().toString();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // 获取毫秒并转换为三位数字
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

  // 生成6个随机数并拼接
  const randomNums = Array.from({ length: 6 }, () => Math.floor(Math.random() * 10)).join('');

  // 组合订单号
  const orderNumber = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}${productId}${randomNums}`;

  return orderNumber;
}

export const compareDateTime = (givenDatetime, duration=12) => { // 参数1：为购买日期，参数2: 有效期 单位/月； 对比会员时间是否到期，会员时间默认为12个月 
  // 将给定的 datetime 转换为 Date 对象
  const givenDate = new Date(givenDatetime);

  // 加上 12 个月
  const futureDate = new Date(givenDate);
  futureDate.setMonth(futureDate.getMonth() + duration);

  // 获取当前时间
  const currentDate = new Date();

  // 比较
  if (futureDate < currentDate) {
    return false;
  } else {
    return true;
  }
}

export const formatDate = (dateString)=> {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${year}年${month}月${day}日${hours}时${minutes}分${seconds}秒`;
}
