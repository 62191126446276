/* eslint-disable */
import { createRouter, createWebHashHistory } from "vue-router"
import { homePages, optionPages } from "./pages.js"
import { music_theory_pages, MusicTheoryExaminationPages } from "./musicTheoryRouterPages.js"
import Index from "@/pages/index/index.vue"
import ProductOrder from '@/pages/shop/orders/ProductOrder.vue'
import { beforEach } from "./beforeEach.js"


// 为组件分配地址
const routes = [
  {
    path: '/',
    name: '/',
    content: '首页',
    redirect: '/index'
  },
  {
    path: '/orders',
    name: 'orders',
    content: '订单页',
    component: ProductOrder
  },
  {
    path: '/index',
    name: 'index',
    content: '首页',
    redirect: '/index/AIMusic',
    component: Index,
    children: [
      ...homePages
    ]
  },
  ...optionPages,
  ...music_theory_pages,
  ...MusicTheoryExaminationPages
]

const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes,
})



// 全局前置守卫
router.beforeEach((to, from, next) => {
  beforEach(to, from, next)
})


export default router