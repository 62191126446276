<template>
    <div class="technology-box">
        <TechnologyHeader></TechnologyHeader>
        <router-view></router-view>
    </div>
</template>

<script>
import TechnologyHeader from '@/pages/technology/AudioClear/components/TechnologyHeader'

export default {
    components: {
        TechnologyHeader
    }
}

</script>

<style lang="less" scoped>
.technology-box {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #101010;
}
</style>