<template>
    <div >
        <div>辅助钢琴，请用鼠标点击。中央C开始向上一个8度。请弹奏您自己的数字钢琴旋律吧！</div>
        <div id="piano">
            <ul>
                <li>
                    <div @click="playNote('C3')"></div>
                    <p @click="playNote('C#3')"></p>
                </li>
                <li>
                    <div @click="playNote('D3')"></div>
                    <p @click="playNote('D#3')"></p>
                </li>
                <li>
                    <div @click="playNote('E3')"></div>
                </li>
                <li>
                    <div @click="playNote('F3')"></div>
                    <p @click="playNote('F#3')"></p>
                </li>
                <li>
                    <div @click="playNote('G3')"></div>
                    <p @click="playNote('G#3')"></p>
                </li>
                <li>
                    <div @click="playNote('A3')"></div>
                    <p @click="playNote('A#3')"></p>
                </li>
                <li>
                    <div @click="playNote('B3')"></div>
                </li>

                <li>
                    <div @click="playNote('C4')" class="center-c">C4</div>
                    <p @click="playNote('C#4')"></p>
                </li>
                <li>
                    <div @click="playNote('D4')"></div>
                    <p @click="playNote('D#4')"></p>
                </li>
                <li>
                    <div @click="playNote('E4')"></div>
                </li>
                <li>
                    <div @click="playNote('F4')"></div>
                    <p @click="playNote('F#4')"></p>
                </li>
                <li>
                    <div @click="playNote('G4')"></div>
                    <p @click="playNote('G#4')"></p>
                </li>
                <li>
                    <div @click="playNote('A4')"></div>
                    <p @click="playNote('A#4')"></p>
                </li>
                <li>
                    <div @click="playNote('B4')"></div>
                </li>
                <li>
                    <div @click="playNote('C5')"></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { soundPlayer } from '@/store/soundplayer';

export default {
    name: 'keyBoard',
    methods: {
        playNote(e) {
            const handler = () => {
               
            }
            soundPlayer().makeNotePlay([e], handler)
        }
    }
}
</script>

<style lang="less" scoped>
ul {
    width: 640px;
    height: 240px;
    margin: 32px auto;
}

li {
    float: left;
    list-style-type: none;
    position: relative;
    cursor: pointer;
}

li>div {
    height: 240px;
    width: 40px;
    border: 2px solid;
    border-color: #666;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-sizing: border-box;
    box-shadow:
        /*inset  3px 0  10px  #c9c6c6,*/
        inset 3px -5px 0 #eeebeb;
}

li:not(:last-child)>div {
    border-right: none;
}

li>div:hover {
    background: linear-gradient(45deg, #dad9d9, #ffffff);

}

li>div:active {
    box-shadow: inset 3px -4px 0 #eeebeb;
}

li>p {
    height: 132px;
    width: 26px;
    background-color: #000;
    border-bottom-left-radius: 5.5px;
    border-bottom-right-radius: 5.5px;
    box-shadow: inset 3px -4px 0 #2c2c2c;
    position: absolute;
    top: 0;
    left: 26px;
    z-index: 999;
}

li>p:hover {
    background: linear-gradient(45deg, #4c4c4c, #444444);

}

li>p:active {
    box-shadow: inset 3px -5px 0 #2c2c2c;

}

.center-c {
    display: flex; 
    align-items: end; 
    justify-content: center; 
    font-size: 16px; 
    font-weight: 400; 
    padding-bottom: 12px;
}
</style>