<template>
  <div class="home-container">
    <img style="margin-top: -64px;" :src="logo_src" alt="" @click="linkTo('/home')">
    <h1 style="margin-bottom: 64px;">音乐基础理论与常识考试模拟练习题</h1>
    <ButtonAdver  @onclick="linkTo('/musicFoundation/NotePitchSing')" title="开始学习" name="arrow-right"></ButtonAdver >
  </div>
</template>

<script>
import { logo_src} from "@/tools/MusicTheoryTools"
import ButtonAdver from "@/components/button-adver";
// import { userstore } from "@/store/userinfo"

export default {
  name: 'HomePage',
  components: {
    ButtonAdver 
  },
  data() {
    return {
      logo_src: logo_src,
      // store: userstore()
    }
  },
  // mounted(){
  //   storageWatch() // 监听本地localstorage
  //   const store = userstore() // 更新store中的userinfo
  //   store.updateUserInfo()
  // },
  methods: {
    linkTo(e){
      this.$router.push(e)
    }
  }

}
</script>

<style lang="less">


.home-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
</style>