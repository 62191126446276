 /* eslint-disable */
import { get } from "@/tools/tool"
import { userstore } from "@/store/userinfo";
// 检查token是否合法，返回true或是false
export const isTokenOk = async () => {
  const token = localStorage.getItem("duoyintoken");

  if (token) { 
    const tokenInfo = await get('/web/checkin', 'token=' + token)
    if(tokenInfo == 'expired' || tokenInfo == 'invalid'){
      userstore().clearRegisterInfo()
      return {
        status: 401,
        message: 'token已超时或不合法'
      }
    }else{
      await userstore().updateUserInfo()
      return {
        status: 200,
        tokenInfo: tokenInfo
      }
    }
  }else{
    return {
      status: 400,
      message: '没有本地token'
    }
  }
}