/* eslint-disable */
import { TestAssets } from "./assits";

export class StaveMode {

    constructor(div, page, askfor) {
        TestAssets.questionInfoInit(this, div, page, askfor)
    }

    InitQuestion() {
        this.questionIntros = [this.questionIntros[0]]
        this.questionIntro = this.questionIntros[0]
        this.notesBox = this.questionIntro.notesBox; // 当前级别需要的音符数组

        TestAssets.InitVariate(this)
        this.makeAnswerBox(); // 生成答题卡

    }
    makeAnswerBox() {    // 参数1，音符，参数2，音名还是唱名
        this.answerBox = answerByPage(this);
    }
    judgmente() {
        TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
    }
    finished() {
        TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
    }
    rateChange(e) { // 更改答题难度
        TestAssets.rateChange(e, this.level, this.questionIntros, this)
    }
}


const answerByPage = (that) => {
    const note = TestAssets.makeNote(that.notesBox); // 生成一个随机音符，也就是要考的音高
    const modeNameBox = ['C', 'D', 'G', 'F', 'A', 'Bb', 'E', 'Eb', 'B', 'Ab']
    const mode = modeNameBox[Math.floor(Math.random() * modeNameBox.length)]
    TestAssets.NotePrinterSenior(that.div, [{
        note: [note],
        duration: '4',
        count: {
            num: 4,
        }
    }],
        mode
    );
    const indexBox = ["A", "B", "C", "D"];

    if (that.page == 'TonicSolfa') {

        const box = TestAssets.notesBox.up.filter(item => item.indexOf('#') == -1)
        const index = box.map(item => item[0]).indexOf(mode[0])
        const newBox = box.slice(index, index + 7)
        const gread = newBox.map(item => item[0]).indexOf(note[0]) + 1
        const optionBox = TestAssets.getRandomFour(gread, [1, 2, 3, 4, 5, 6, 7, 8])

        let answerBox = [];

        for (let i = 0; i < optionBox.length; i++) {
            answerBox.push({
                index: indexBox[i],
                value: optionBox[i],
                label: `${indexBox[i]}, 曲谱上音符的音级是：${optionBox[i]}级 `,
                isCorrect: gread == optionBox[i],
            });
        }


        return answerBox;


    } else {
        const optionBox = TestAssets.makeRandomNotes(mode, modeNameBox)
        let answerBox = [];

        for (let i = 0; i < optionBox.length; i++) {
            answerBox.push({
                index: indexBox[i],
                value: optionBox[i],
                label: `${indexBox[i]}, 曲谱的调号是：${optionBox[i]} 大调`,
                isCorrect: mode == optionBox[i],
            });
        }


        return answerBox;
    }
}