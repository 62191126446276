<template>
  <div class="studio-adver">
    <div class="adver-box">
      <div class="info-box">
        <div class="info">
          <div>
            <div class="title">多音版权音乐工作室</div>
            <div class="sub-title">Polyphonic Music Studio</div>
            <div class="content-info">
              让每个音符讲述不同的故事。我们专注于原创流行音乐、游戏配乐、电影原声带的精心打造，以及专业的音效设计。无论是录音还是混音，我们都致力于将您的声音完美呈现。创意与专业，只为您的听觉盛宴！
            </div>
          </div>
        </div>

        <div class="info-bottom">
          <ButtonAdver title="我要作曲" name="arrow-right"></ButtonAdver>
        </div>

      </div>

      <div>
        <t-image  src="https://www.duoyinchina.com/assits/images/音乐工作室海报——美女弹古筝.png"
          :style="{ background: 'none', width: '560px', display: isLoaded ? '' : 'none'}" @load="isLoaded = true"></t-image>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import ButtonAdver from "@/components/button-adver.vue"

export default {
  name: 'SwiperPage',
  components: {
    ButtonAdver
  },
  data(){
    return {
      isLoaded: false
    }
  }
}
</script>

<style lang="less" scoped>
@import "/src//style/root.less";
.studio-adver {
  width: 100vw;
  height: 720px;
  display: flex;
  justify-content: center;

  .adver-box {
    width: var(--pc-content-width);
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

  .info-box {
    width: 600px;
    height: 500px;
    display: flex;
    flex-direction: column;
    position: relative;

    .info {
      width: 500px;
      height: 300px;

      .title {
        font-size: 32px;
        font-weight: 500;
        color: rgb(40, 40, 43);
      }

      .sub-title {
        font-size: 17px;
        margin-top: 16px;
        color: rgb(58, 56, 62);
      }

      .content-info {
        margin-top: 16px;
        text-align: left;
        font-size: 17px;
        color: rgb(58, 56, 62);
      }
    }
  }
}


}
</style>