<template>
    <t-button class="btn-adver" theme="default" size="large" shape="round" @click="onClick">
      <template #suffix><t-icon :name="name"></t-icon></template>
      {{ title }}
    </t-button>
</template>

<script>
export default {
    name: "button-adver-page",
    props: ['title', 'name'],
    methods: {
        onClick(){
            this.$emit('onclick')
        }
    }
}
</script>


<style lang="less"> 



.btn-adver {
    height: 48px !important;
    color: var(--background-color) !important;
    background-color: #222222 !important;
}

.btn-adver:hover {
    background-color: var(--theme-color) !important;
    text-shadow: 0 0 3px var(--background-color);
}
</style>