<template>
    <div class="options-box">
        <t-radio-group class="answer-box" :value="TestPaper.answer" @change="answerChange">
            <t-radio class="answer-radio" placement="right" v-for="(item, index) in TestPaper.answerBox" :key="index"
                :value="item.index" borderless>
                <template #label>
                    <div class="stave4-box">
                        <p class="answer-box-radio-p">{{ item.label }} </p>
                        <div :id="'stave' + item.index" class="stave"></div>
                    </div>
                </template>
            </t-radio>
        </t-radio-group>
    </div>
</template>

<script>
export default {
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            TestPaper: null,
        }
    },
    methods: {
        answerChange(e) {
            this.TestPaper.answer = e;
        }
    }
}
</script>
<style lang="less" scoped>
@import url('/src/style/root.less');

.options-box {
    margin-top: var(--pc-margin);
    .answer-box {
        .answer-radio {
            box-sizing: border-box;
            overflow: hidden ;


            .stave4-box {    
                display: flex; 
                align-items: center;
                height: 180px;

                #staveA, #staveB, #staveC, #staveD{
                    background-color: #ffffff;
                }
            }

        }
    }
}
</style>