<template>

    <div class="knowledge-base-box">

        <div class="image-area">
            <t-image @click="getImage" class="image" src="https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/knowledge/NotePitchNameKnowledge.png" fit="cover"></t-image>
        </div>

        <div class="text-area">

            音名是音乐术语，代表特定音高的名称。
            <br><br>
            音乐中的音名用于标识不同的音级，这些音级在音乐理论中是非常基础的概念。
            <br><br>
            在音乐体系中，有七个基本音级，它们分别用字母C、D、E、F、G、A、B来表示。这些音名代表了音阶中的各个音，其音高是固定的，不会随着调式的变化而变化。
            音名与唱名是两个不同的概念。唱名是在演唱音乐时使用的名称，如Do、Re、Mi、Fa、Sol、La、Si，它们是为了方便唱谱而采用的，并且在不同的唱名法中可能有所不同。
            <br><br>
            例如，首调唱名法中的Do始终代表主音，而固定唱名法中Do始终代表C音。

        </div>

    </div>

    <t-image-viewer
      v-model:visible="isOpenImgPreViewer"
      :images="[PreImgSrc]"
      @close="isOpenImgPreViewer=false"
    >
    </t-image-viewer>

</template>

<script>
export default {
    data(){
        return {
            PreImgSrc: '',
            isOpenImgPreViewer: false
        }
    },
    methods: {
        getImage(e){
            this.PreImgSrc = e.srcElement.currentSrc;
            this.isOpenImgPreViewer = true
        }
    }
}
</script>

<style lang="less" scoped>
@import url(./style/knowledgeBase.less);
</style>