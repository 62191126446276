import { Vex } from "vexflow"; // 
/* eslint-disable */
import { doesNotContainAny } from "./_assits_help";

const {
  // Factory, 
  Renderer, Stave, StaveNote, Formatter, Voice, Dot, Beam, Accidental } = Vex.Flow;
const regex = /\d+/g; // 数字筛选的正则表达式

const getDiv = (id, width = 600, height = 360) => { // 取得vexflow要使用的svg；参数1:div的id；参数2:svg的宽度；参数3:svg的高度
  const div = document.getElementById(id);
  const renderer = new Renderer(div, Renderer.Backends.SVG);
  renderer.resize(width, height);
  return renderer.getContext();
}
const dotted = (staveNote, noteIndex = -1) => { // 附点音符
  if (noteIndex < 0) {
    Dot.buildAndAttach([staveNote], {
      all: true,
    });
  } else {
    Dot.buildAndAttach([staveNote], {
      index: noteIndex,
    });
  }
  return staveNote;
}
const makeStaveNotes = (notes) => { // 参数格式[{note: [item], duration: '4', count: {num: 4,}}]
  return [
    ...notes.map((item) => {
      // 制作音符格式
      const keys = item.note.map(note => { // 将C4这样格式的音高，转成C/4这样的
        const key = note.match(regex); // 提取数字
        return `${note.replace(key, '')}/${key}`
      })

      let clef = ''

      if (item.note[0].match(regex) > 3) { // 根据音高定义无限谱类型
        // 根据音高分配谱好
        clef = "treble";
      } else {
        clef = "bass";
      }

      let stav = null;

      stav = new StaveNote({ keys: [...keys], duration: item.duration, clef: clef })
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].indexOf('#') !== -1) {
          stav = stav.addModifier(new Accidental("#"), i)
        } else if (keys[i].indexOf('b') !== -1) {
          stav = stav.addModifier(new Accidental("b"), i)
        }
      }

      if (item.duration.indexOf('d') !== -1) {
        return dotted(
          stav,
          0
        )
      } else {

        return stav

      }
    })
  ];
}
/*
  使用方式
  参数1: 音符数组对象大数组中套小数组，小数组中是对象，有keys，音符数组，有duration是音符时值；
  返回：allNotes:链接到一起的音符数组；beams:在音符上添加连接线，组成完整的一拍
  const {allNotes, beams} = makeTempoNotes([
    [
      {
        keys: ['C#/4'],
        duration: '8d'
      },
      {
        keys: ['B/4'],
        duration: '16'
      }
    ],

    [
      {
        keys: ['C/4'],
        duration: '16'
      },
      {
        keys: ['C/4'],
        duration: '16'
      },
      {
        keys: ['C/4'],
        duration: '8'
      },
    ],
    [
      {
        keys: ['C/4'],
        duration: '16'
      },
      {
        keys: ['C/4'],
        duration: '16'
      },
      {
        keys: ['C/4'],
        duration: '8'
      },
    ],
    [
      {
        keys: ['C/4'],
        duration: '4'
      }
    ],
])



*/

const makeTempoNotes = (tempoNotes) => {

  let box = []

  let num = tempoNotes[0][0].keys[0].match(regex)
  let clef = 'treble'

  if (num > 3 || (doesNotContainAny(tempoNotes[0][0].keys[0], ['A', 'B']) && num == 3)) {
    // 根据音高分配谱好
    clef = "treble";
  } else {
    clef = "bass";
  }

  tempoNotes.forEach(tempo => {
    box.push(
      tempo.map(item => {

        let keys = item.keys;
        let stav = new StaveNote({ keys: keys, duration: item.duration, clef: clef });

        for (let i = 0; i < keys.length; i++) {

          if (keys[i].indexOf('#') !== -1) {
            stav = stav.addModifier(new Accidental("#"), i)
          } else if (keys[i].indexOf('b') !== -1) {
            stav = stav.addModifier(new Accidental("b"), i)
          }
        }

        if (item.duration.indexOf('d') !== -1) {
          return dotted(
            stav,
            0
          )
        } else {
          return stav
        }
      })
    )
  })

  let allNotes = box[0]
  let beams = [];

  for (let i = 1; i < box.length; i++) {
    allNotes = allNotes.concat(box[i]);
  }

  for (let i = 0; i < box.length; i++) {
    if (box[i].length > 1) {
      beams.push(new Beam(box[i]))
    }
  }

  return { allNotes, beams };
}

/*
  功能：将一个音符或是一个数组的音符 打印到Html组件
  参数1: div的id，
  参数2: 音符数组传给makeTempoNotes的
  参数3: 可以写速度术语的文字
  参数4: 拍号
*/

export const NoteTempoPrinter = (id, tempoNotes, tempoTitle = null, rhythm = '4/4') => {
  document.getElementById(id).innerHTML = null;

  const context = getDiv(id, 600, 180);

  if (tempoTitle) {
    context.font = "bold normal 20px Georgia";
    context.fillText(tempoTitle, 20, 50)
  }

  let n = tempoNotes[0][0].keys[0].split('/')[1]

  let clef = 'treble'
  if (n > 3 || (doesNotContainAny(tempoNotes[0][0].keys[0], ['A', 'B', 'G']) && n == 3)) {
    clef = 'treble'
  } else {
    clef = 'bass'
  }

  const { allNotes, beams } = makeTempoNotes(tempoNotes)

  const stave = new Stave(20, 30, 560);

  stave.addClef(clef).addTimeSignature(rhythm);

  stave.setContext(context).draw();

  Formatter.FormatAndDraw(context, stave, allNotes);

  beams.forEach((b) => {
    b.setContext(context).draw();
  });
}

/*
  功能：将一个音符或是一个数组的音符打印到Html组件，
  参数1:div的id；
  参数2: 格式要求：音符数组[{count:{num: 4}, duration: "16", note: ['F4']}]
  
  const notes = [["Gb4", "B4"],["Ab4", "E4"],["Db4", "Bb4"],["G4", "Bb4"]]

   setTimeout(() => { // 打印音符
      for (let i = 0; i < 4; i++) {
        TestAssets.NotePrinterSenior(div[i], [...notes[i].map(item => {
          return {
            note: [item],
            duration: '4',
            count: {
              num: 4,
            }
          }
        })]);
      }
    }, 0);
*/

export const NotePrinterSenior = (id, notes, Mode = '', width = 400, height = 160) => {

  document.getElementById(id).innerHTML = null;

  const context = getDiv(id, width, height)

  const noteList = makeStaveNotes(notes)

  const num_beats = notes.reduce((a, b) => a + b.count.num, 0)
  const result = notes[0].note[0].match(regex)[0]; // 返回音符里的数字

  const voice = new Voice({ num_beats: num_beats, beat_value: 16 });
  // 创建空白五线谱大谱表和拍好
  const trebleStave = new Stave(20, 20, width - 40);
  trebleStave.addClef("treble").addTimeSignature('4/4');

  const bassStave = new Stave(20, 20, width - 40);
  bassStave.addClef("bass").addTimeSignature("4/4");

  voice.addTickables(noteList);
  new Formatter().joinVoices([voice]).format([voice], 350);

  if (result > 3) {

    if (Mode) {
      trebleStave.addKeySignature(Mode)
    }
    trebleStave.setContext(context).draw();
    voice.draw(context, trebleStave);
  } else {
    if (Mode) {
      bassStave.addKeySignature(Mode)
    }
    bassStave.setContext(context).draw();
    voice.draw(context, bassStave);
  }

}