<template>
    <TestPitch :info="info"></TestPitch>
</template>

<script>
/* eslint-disable */
import TestPitch from '@/components/MusicTheoryComponents/TestPitch.vue';
import { TempoMark } from "@/tools/MusicTheoryTools";

export default {
    components: {
        TestPitch
    },
    data() {
        return {
            info: new TempoMark('stave-paper', 'TempoMark', 'name')
        }
    }
}
</script>
