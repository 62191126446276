/* eslint-disable */
export * from "./_vexflow"
export * from "./_audio"
export * from "./_wavesurfer"
export * from "./_assits_help"
import * as helper from "./_assits_help"
import { DialogPlugin, MessagePlugin } from "tdesign-vue-next";
import { educationPages } from "@/router/_musicTheoryRouterPages"
import { get8notesfromArray } from "./_assits_help"

const makePrimaryNotes = (info, isSenior) => { // 参数1:为路由对象的基础设置; 参数2:根据数组长度判断的章节

  const levelBox = ['一级', '二级', '三级', '四级', '五级']

  const notesBox = helper.makeNotesBox(); // 基础音符盒子

  const sj = ['up', 'down']
  const mark = sj[Math.floor(Math.random() * sj.length)]

  let i = Math.floor(Math.random() * 35)
  let l = Math.floor(Math.random() * 12)

  let primaryNotes = isSenior ? [
    notesBox[mark].slice(39, 52),
    notesBox[mark].slice(26, 39),
    notesBox[mark].slice(24, 63),
    notesBox[mark].slice(15, 63),
    notesBox[mark].slice(15, 63),
  ] : [  // 初级水平的音符盒子
    notesBox.up.slice(39, 51).filter((item) => item.indexOf("#") == -1),
    notesBox.up.slice(27, 39).filter((item) => item.indexOf("#") == -1),
    notesBox.up.slice(27, 51).filter((item) => item.indexOf("#") == -1),
    notesBox.up.slice(15, 63).filter((item) => item.indexOf("#") == -1),
    notesBox.up.slice(15, 63).filter((item) => item.indexOf("#") == -1)
  ]

  const levelInfo = [
    '高音谱号，中央C向上一个八度',
    '低音谱号，中央C向下一个八度',
    '中央C上下两个八度',
    '中央C上下四个八度',
    '中央C上下水四个八度'
  ]

  return primaryNotes.map((item, index) => {
    return {
      label: levelBox[index],
      level: index + 1,
      value: index + 1,
      info: levelInfo[index],
      notesBox: get8notesfromArray(item),
      title: info.menuType + '-' + info.content,
      time: index < 4 ? null : 20
    }
  })

}
export const PrimaryQuestionIntros = (pageName, isSenior = false) => { // 根据路由信息页面定义的问题描述对象，加入基础音符盒子的相关信息，生成标准问题描述信息
  return Array.from({ length: 5 }, (_, i) => {
    return {
      ...educationPages.find(item => item.name == pageName),
      ...makePrimaryNotes(educationPages.find(item => item.name == pageName), isSenior)[i]
    }
  })
}
export const questionInfoInit = (that, div, page, askfor) => { // 定义题库的基础信息参数
  that.div = div; // div的id
  that.level = 1; // 难度等级
  that.page = page // 当前页面地址
  that.questionIntros = PrimaryQuestionIntros(page);
  that.questionIntro = that.questionIntros.find(
    // 根据难度等级选择题目描述
    (item) => item.value == that.level
  );
  that.notesBox = that.questionIntro.notesBox; // 当前级别需要的音符数组

  that.askfor = askfor; // 请求目的

  that.singBox = helper.singBox; // 唱名盒子

  that.score = 0; // 当前分数

  that.question_num = 0; // 答题序号

  that.answerBox = [];  // 选项盒子

  that.isPlaying = false; // 是否正在播放

  that.counter = 0; // 计时器

  that.wavesurfer = null;

  that.answer = '';

  that.result = '';

  that.answerBox = [];

  that.questionSheet = ''

}
export const InitVariate = (that) => {

  if (that.question_num > 9) {
    that.finished();
    return;
  }

  that.question_num = that.question_num + 1; // 自动增加问题序号

  that.answer = ""; // 清空上一次选择的答案

  that.notes = [];

  that.isPlaying = false;

  that.isReady = false;

}
export const judgmente = (answer, answerBox, question_num, that) => { // 参数1: 选项ABCD; 参数2: 选项盒子 数组; 参数3:当前题序号 Number; 参数4: 计数器变量; 参数5: 需要执行的函数；返回: right / wrong
  // 销毁全部web语音播放
  if (answer && answerBox.find((item) => item.index == answer).isCorrect) { // 根据选项判断回答是否正确
    // 如果答题结果是正确的

    MessagePlugin.success("回答正确！", 1000); // 显示通知
    that.score = that.score + 10
    that.result = 'right'

    setTimeout(() => {
      that.InitQuestion()
      that.result = ''
    }, 1000);

  } else {
    that.result = 'wrong'
    // 如果回答错误
    const alertDia = DialogPlugin.alert({
      // 显示选择性的通知
      header: `第${question_num}题，回答错误！！`,
      body: `正确的选项是: ${answerBox.filter((item) => item.isCorrect)[0].label
        }`,
      confirmBtn: "已了解",
      onConfirm: () => {
        alertDia.destroy();
        that.InitQuestion()
        that.result = ''
      },
      onClose: () => {
        alertDia.destroy();
        that.InitQuestion()
        that.result = ''
      },
    });
  }
}
export const rateChange = (e, recorded, questionIntros, that) => { // 更改答题难度; 参数1: 选择的级别 1-5 Number; 参数2: Number 当前级别; 参数3: 问题数组对象；参数4: 回调函数
  const level = e;

  if (recorded == level) {
    return;
  }

  const questionIntro = questionIntros.find(
    (item) => item.value == level
  );

  const confirmDia = DialogPlugin.confirm({
    header:
      "是否将当前级别" +
      (level < recorded ? "下降到" : "提升到") +
      ("【" + questionIntro.label + "】水平？"),
    // body: "该级别的能力要求是：" + questionIntro.info,
    confirmBtn: "确认",
    cancelBtn: "取消",
    onConfirm: () => {
      that.level = level;
      that.question_num = 0;
      that.score = 0;
      that.questionIntro = that.questionIntros.find(
        (item) => item.value == level
      );
      that.InitQuestion();

      confirmDia.destroy();
    },
    onClose: () => {
      confirmDia.destroy();
    },
  });
}
export const finished = (score, level, length, that, isTest = false) => { // 参数1: Number 分数；参数2: Number 级别; 参数3:级别的总数；
  const confirmDia = DialogPlugin.confirm({
    header: "答卷完毕，总计：" + score + "分。" + "共答对" + score / 10 + "题，答错" + (100 - score) / 10 + "题。",
    body: score < 50 ? "先看讲解再答题，会进步更快哈。" : score < 60 ? "差一点就几个了，在加油啊!" : score < 80 ? "不错，再接再厉" : score < 100 ? "满分就在眼前，努力！"
      : score == 100 ? "太棒啦！满分！棒棒的！" : "加油",
    confirmBtn: level == length || isTest ? null : "下一级别",
    cancelBtn: "再来一次",
    onConfirm: () => {
      if (score >= 60) {
        level = Number(level) + 1;
      } else {
        MessagePlugin.error(
          "未达到60分及格线，请继续努力，过线后可进入下一级别.."
        );
      }
      that.level = level;
      that.question_num = 0;
      that.score = 0;
      that.InitQuestion();
      confirmDia.destroy();
    },
    onClose: () => {
      that.level = level;
      that.question_num = 0;
      that.score = 0;
      that.InitQuestion();
      confirmDia.destroy();
    },
  });

}