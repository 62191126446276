/* eslint-disable */
import { TestAssets } from "./assits";

export class QuestionIntervalFirst {
  
  constructor(div, page) {
    TestAssets.questionInfoInit( this, div, page )
  }

  InitQuestion() {
    
    this.questionIntro = this.questionIntros.find((item) => item.value == this.level); // 根据难度等级选择题目描述
    this.notesBox = this.questionIntro.notesBox;

    TestAssets.InitVariate(this)
    this.makeAnswerBox(); // 生成答题卡

  }
  // 参数1，音符，参数2，音名还是唱名
  makeAnswerBox() {

    this.note = TestAssets.makeNote(this.notesBox); // 生成一个随机音符，也就是要考的音高
  
    const interval = Math.floor(Math.random() * 7) + 2; // 返回随机生成的音程

    const noteslist = TestAssets.notesBox.up.filter((item) => item.indexOf("#") == -1)

    const note1 = noteslist[noteslist.indexOf(this.note) + interval - 1]
    

    TestAssets.NotePrinterSenior(this.div, [{
      note: [this.note, note1],
      duration: '4',
      count: {
        num: 4,
      }
    }]);
    
    TestAssets.NotePrinterSenior(this.div, [{
      note: [this.note, note1],
      duration: '4',
      count: {
        num: 4,
      }
    }]);

    const indexBox = ['A', 'B', 'C', 'D'];
    const optionBox = generateRandomInterval4(interval);
    let answerBox = [];

    for (let i = 0; i < optionBox.length; i++) {
      answerBox.push({
        index: indexBox[i],
        value: optionBox[i],
        label: `${indexBox[i]}, 曲谱上音符之间的音程是：${optionBox[i]} 度`,
        isCorrect: interval == optionBox[i]
      });
    }

    this.answerBox = answerBox;
  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
  }
  rateChange(e) { // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this)
  }
}

// 返回4个音程的答案
const generateRandomInterval4 = (input) => {
  if (input < 2 || input > 8) {
    throw new Error("输入的数字必须在2到8之间");
  }
  const box = [2, 3, 4, 5, 6, 7, 8];
  const numbers = box.filter(item => item !== input);

  const result = [];

  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * numbers.length);
    result.push(numbers[randomIndex]);
    numbers.splice(randomIndex, 1);
  }

  result.push(input);
  return result.sort(() => Math.random() - 0.5);
}

