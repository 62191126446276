<template>
    <TestListening :info="info"></TestListening>
</template>


<script>
import TestListening from '@/components/MusicTheoryComponents/TestListening.vue';
import { ListeningPitchFirst } from "@/tools/MusicTheoryTools";

export default {
    components: {
        TestListening
    },
    data(){
        return {
            info: new ListeningPitchFirst(
                [
                    "staveA",
                    "staveB",
                    "staveC",
                    "staveD",
                ],
                'ListeningIntervalPrimary'
            )
        }
    }
}
</script>
