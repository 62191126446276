<template>
    <div class="studio">
        <Adver></Adver>
        <DividerLine title="游戏与影视音乐项目展示" subTitle="Game and Film Music Project Showcase" :align="'left'"></DividerLine>
        <AudioWaves></AudioWaves>
        <StudioSwiper></StudioSwiper>
        <DividerLine title="流行、游戏、影视原创音乐项目制作流程" subTitle="Pop, Game, Film and TV Original Music Project Production Process" :align="'center'" :marginTop="'60px'"></DividerLine>
        <ProductionBusiness></ProductionBusiness>
        <DividerLine title="多音（北京、天津）录音棚、AI音乐工作室地址" subTitle="DuoYin Recording Studio, AI Music Studio Address (Beijing, Tianjin)" :align="'center'" :marginTop="'60px'"></DividerLine>
        <MapContainer></MapContainer>
        <WebLaw></WebLaw>
    </div>
  </template>
  
  <script>
  import Adver from './components/Adver.vue'
  import StudioSwiper from './components/StudioSwiper.vue'
  import AudioWaves from './components/AudioWaves.vue';
  import ProductionBusiness from './components/ProductionBusiness.vue'
  import DividerLine from './components/DividerLine.vue'
  import MapContainer from '@/components/MapContainer.vue';
  import WebLaw from '@/components/WebLaw.vue';

  export default {
    name: 'HomePage',
    components: {
        Adver,
        StudioSwiper,
        AudioWaves,
        DividerLine,
        ProductionBusiness,
        MapContainer,
        WebLaw
    },
  }
  </script>
  
  <style lang="less" scoped>
  .studio {
    width: 100vw;
    background: none;
    background-color: var(--background-color);
    overflow: hidden;
  }
  </style>