<template>
    <CommonKnowledgeAudio :info="info"></CommonKnowledgeAudio>
</template>


<script>
import CommonKnowledgeAudio from '@/components/MusicTheoryComponents/CommonKnowledgeAudio.vue';
import { AreaMusic } from "@/tools/MusicTheoryTools";

export default {
    components: {
        CommonKnowledgeAudio
    },
    data() {
        return {
            info: new AreaMusic('audio', 'HumanMusic', '央院初级听辩歌唱声部及演唱形式')
        }
    }
}
</script>
