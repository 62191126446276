<template>

    <div class="knowledge-base-box">

        <div class="image-area">
            <t-image @click="getImage" class="image"
                src="https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/knowledge/NoteInterval12LowKnowledge.png"
                fit="cover"></t-image>
        </div>

        <div class="text-area">
            全音和半音关系是音乐理论中描述两个音符间距离的基本概念。
            <br><br>
            全音和半音是构成音乐尺度的基础，它们代表了不同的频率关系。以下是关于全音和半音关系的一些详细解释：
            <br><br>
            半音：是音乐中最小的间隔单位，在平均律中，相邻的两个键之间的音高关系就是半音。例如，从C到C#或者从B到C，都是半音关系。
            <br><br>
            全音：由两个半音构成，即在一个八度音阶内，紧邻的三个音符之间就构成了全音的关系。比如从C到D或者从G到A，都是全音关系。
            <br><br>
            总的来说，了解全音和半音的概念对于学习音乐理论、掌握音阶构成和和弦构成是非常重要的基础。
        </div>


    </div>

    <t-image-viewer v-model:visible="isOpenImgPreViewer" :images="[PreImgSrc]" @close="isOpenImgPreViewer = false">
    </t-image-viewer>
</template>

<script>
export default {
    data() {
        return {
            PreImgSrc: "",
            isOpenImgPreViewer: false,
        };
    },
    methods: {
        getImage(e) {
            this.PreImgSrc = e.srcElement.currentSrc;
            this.isOpenImgPreViewer = true;
        },
    },
};
</script>

<style lang="less" scoped>
@import url('./style/knowledgeBase.less');
</style>