<template>
    <div class="audio-waves-box">

        <div class="audio-info">
            <div class="audio-backimg">
                <t-icon class="audio-play" :name="isPlaying ? 'pause-circle' : 'play-circle'" size="24"
                    @click="makeC4Play"></t-icon>
            </div>
            <div>中央C</div>
        </div>

        <div class="audio-info">
            <div class="audio-backimg">
                <t-icon class="audio-play" :name="TestPaper.isPlaying ? 'pause-circle' : 'play-circle'" size="24"
                    @click="makeNotePlay"></t-icon>
            </div>
            <div>请听辨</div>
        </div>
    </div>
</template>


<script>
import { TestAssets } from "@/tools/MusicTheoryTools";
export default {
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            TestPaper: null,
            isPlaying: false
        }
    },
    methods: {
        makeC4Play() {
            const handler = (isPlaying) => {
                this.isPlaying = isPlaying
            }
            TestAssets.makeNotePlay(['C4'], handler)
        },
        makeNotePlay(){
            this.TestPaper.makeNotePlay()
        }
    }
}
</script>
<style lang="less">


.audio-waves-box {
    display: flex;
    align-items: center;
    width: 140px;
    justify-content: space-between;
    margin-top: 32px;

    .audio-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .audio-backimg {
            background-image: url("https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/img/piano.png");
            width: 60px;
            height: 60px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .audio-play {
                color: #ffffff;
            }
        }
    }
}
</style>