/* eslint-disable */
import { TestAssets } from "./assits";

let rhythmAnswerBox = [
  {
    title: null,
    NoteRhythmCount: '四拍',
    NoteRhythmName: '全音符',
    duration: '1',
    count: {
      num: 16,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '休息两拍',
    NoteRhythmName: '二分休止符',
    duration: '1r',
    count: {
      num: 16,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '两拍',
    NoteRhythmName: '二分音符',
    duration: '2',
    count: {
      num: 8,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '休息四拍',
    NoteRhythmName: '全休止符',
    duration: '2r',
    count: {
      num: 8,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '一拍',
    NoteRhythmName: '四分音符',
    duration: '4',
    count: {
      num: 4,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '休息一拍',
    NoteRhythmName: '四分休止符',
    duration: '4r',
    count: {
      num: 4,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '半拍',
    NoteRhythmName: '八分音符',
    duration: '8',
    count: {
      num: 2,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '休息半拍',
    NoteRhythmName: '八分休止符',
    duration: '8r',
    count: {
      num: 2,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '四分之一拍',
    NoteRhythmName: '十六分音符',
    duration: '16',
    count: {
      num: 1,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '休息四分之一拍',
    NoteRhythmName: '十六分休止符',
    duration: '16r',
    count: {
      num: 1,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '三拍',
    NoteRhythmName: '附点二分音符',
    duration: '2d',
    count: {
      num: 12,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '一拍半',
    NoteRhythmName: '附点四分音符',
    duration: '4d',
    count: {
      num: 6,
      beat: 16
    }
  },
  {
    title: null,
    NoteRhythmCount: '四分之三拍',
    NoteRhythmName: '附点八分音符',
    duration: '8d',
    count: {
      num: 3,
      beat: 16
    }
  }
]

export class QuestionRhythmFirst {

  constructor(div, page) {
    TestAssets.questionInfoInit(this, div, page)

    this.rhythmAnswerBox = rhythmAnswerBox.map(item=>{
      item.title = item[page]
      return item
    })
  }

  InitQuestion() {
    this.questionIntros = [this.questionIntros[0]]
    this.questionIntro = this.questionIntros[0]

    this.notesBox = this.questionIntro.notesBox; // 当前级别需要的音符数组

    TestAssets.InitVariate(this)
    this.makeRhythmAnswerBox(); // 生成答题卡

  }

  // 参数1，音符，参数2，音名还是唱名
  makeRhythmAnswerBox() {
    const rhythm = TestAssets.makeNote(this.rhythmAnswerBox);

    TestAssets.NotePrinterSenior(this.div, [
      {
        note: ['C5'],
        duration: rhythm.duration,
        count: rhythm.count
      }
    ]);

    // 返回4个音符，包括一个正确答案的
    const indexBox = ["A", "B", "C", "D"];
    const optionBox = [...this.rhythmAnswerBox.filter(item => item.title !== rhythm.title).slice(0, 3), rhythm].sort(() => Math.random() - 0.5)

    let answerBox = [];

    for (let i = 0; i < optionBox.length; i++) {
      answerBox.push({
        index: indexBox[i],
        value: optionBox[i].title,
        label: `${indexBox[i]}, ${this.questionIntro.answerTitle} ${optionBox[i].title}`,
        isCorrect: rhythm.duration == optionBox[i].duration,
      });
    }
    this.answerBox = answerBox;
  }

  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
  }
  rateChange(e) { // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this)
  }
}

