/* eslint-disable */
import { TestAssets } from "./assits";

export class TempoMark {
  constructor(div, page) {
    TestAssets.questionInfoInit(this, div, page)
  }

  InitQuestion() {
    this.questionIntros = this.questionIntros.splice(0, 2)
    this.questionIntro = this.questionIntros[0]; // 根据难度等级选择题目描述

    this.notesBox = this.questionIntros[0].notesBox; // 当前级别需要的音符数组

    TestAssets.InitVariate(this)

    this.makeAnswerBox(); // 生成答题卡

  }

  makeAnswerBox() {
    const indexBox = ["A", "B", "C", "D"];

    const tempoNames = TestAssets.randomTempoNotesBox(this.notesBox, 4) // 生成随机节奏音符

    let TempoMarks = TestAssets.TempoMarks[this.level == 1 ? 'primary' : 'senior']; // 速度术语盒子

    const answerTempo = TempoMarks[Math.floor(Math.random() * TempoMarks.length)] // 返回要求的答案

    TestAssets.NoteTempoPrinter(this.div, tempoNames, answerTempo.name); // 打印随机生成的音符，和提问用的速度术语

    TempoMarks = [...TempoMarks.filter(item => item.name !== answerTempo.name).sort(() => Math.random() - 0.5).slice(0, 3), answerTempo].sort(() => Math.random() - 0.5); // 随机打乱数组

    let answerBox = [];

    for (let i = 0; i < TempoMarks.length; i++) {

      answerBox.push({
        index: indexBox[i],
        value: TempoMarks[i].call,
        label: `${indexBox[i]}, 曲谱上的速度术语是：${TempoMarks[i].call}`,
        isCorrect: answerTempo.call == TempoMarks[i].call
      });
    }

    this.answerBox = answerBox;

  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
  }
  rateChange(e) { // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this)
  }
}
