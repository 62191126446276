/* eslint-disable */
import { TestAssets } from "./assits";

export class TestPitchPrimary {
  constructor(div, page) {
    TestAssets.questionInfoInit( this, div, page )
  }
  InitQuestion() {

    this.questionIntro = this.questionIntros.find((item) => item.value == this.level); // 根据难度等级选择题目描述

    this.notesBox = this.questionIntro.notesBox; // 当前级别需要的音符数组

    TestAssets.InitVariate(this)

    this.makeAnswerBox(); // 生成答题卡

  }
  makeAnswerBox() {
    const note = TestAssets.makeNote(this.notesBox); // 生成一个随机音符，也就是要考的音高

    TestAssets.NotePrinterSenior('stave', [{
      note: [note],
      duration: '4',
      count: {
        num: 4,
      }
    }]);

    const indexBox = ["A", "B", "C", "D"]; // 选项卡

    const notes = TestAssets.makeRandomNotes(note, this.notesBox); // 根据参数音符，随机返回3个其他音符，并组成选项卡音符数组

    let answerBox = [];

    for (let i = 0; i < notes.length; i++) {
      // 选项卡属性确定，index是ABCD选项，value是音符或是答案，label是序号，往往和index一样，isCorrect选项是是否是正确答案
      answerBox.push({
        index: indexBox[i],
        value: notes[i],
        label: `${indexBox[i]}`,
        isCorrect: note == notes[i],
      });
    }

    const div = this.div

    this.answerBox = answerBox;

    setTimeout(() => { // 打印音符
      notes.forEach((item, index)=>{
        TestAssets.NotePrinterSenior(div[index], [{
          note: [item.replace('4', '3')],
          duration: '4',
          count: {
            num: 4,
          }
        }]);
      })
    }, 0);

  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
  }
  rateChange(e) { // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this)
  }
}
