/* eslint-disable */
import { TestAssets } from "./assits";

const regex = /\d+/g; // 数字筛选的正则表达式

export class NoteSequence {

  constructor(div, page) {
    TestAssets.questionInfoInit(this, div, page)
    this.isRandom = false
    this.tempoMusics = []
  }
  InitQuestion() {

    let isSenior = false;

    if (this.page == 'NoteSequencePrimary') {
      this.isRandom = true;
    }

    this.questionIntros = TestAssets.PrimaryQuestionIntros(this.page, isSenior);

    this.questionIntro = this.questionIntros.find(
      (item) => item.value == this.level
    );

    this.notesBox = this.questionIntro.notesBox;
    
    this.tempoMusics = []

    TestAssets.InitVariate(this)

    this.makeAnswerBox(); // 根据随机音符生成答题选项卡

  }
  makeAnswerBox() {

    const indexBox = ["A", "B", "C", "D"]; // 选项卡

    this.tempoMusics = TestAssets.generate_unique_rhythms(this) // 生成4组随机并不重复的节奏乐谱

    this.answerIndex = Math.floor(Math.random() * this.tempoMusics.length) // 选择一组当答案

    let an = JSON.stringify(this.tempoMusics[this.answerIndex]); // 题干

    const notesBoxNature = TestAssets.notesBox.up.filter(item => item.indexOf('#') == -1) // 音符盒子

    const div = this.div

    // 随机将提升1-5
    let answerTempo = JSON.parse(an)
    let randomDistance = Math.floor(Math.random() * this.level + 1)
    for (let i = 0; i < answerTempo.length; i++) {
      for (let j = 0; j < answerTempo[i].length; j++) {
        for (let k = 0; k < answerTempo[i][j].keys.length; k++) {
          let notes = answerTempo[i][j].keys[k]
          let index = notesBoxNature.indexOf(notes.replace('/', ''));
          let notes1 = notesBoxNature[index + randomDistance]
          const n = notes1.match(regex)
          answerTempo[i][j].keys[k] = notes1.replace(n, '/' + n)
        }
      }
    }

    setTimeout(() => { // 打印音符
      for (let i = 0; i < 4; i++) {
        TestAssets.NoteTempoPrinter(div[i], this.tempoMusics[i], ''); // 打印随机生成的音符，和提问用的速度术语
      }
    }, 0);

    TestAssets.NoteTempoPrinter('stave', answerTempo, ''); // 打印随机生成的音符，和提问用的速度术语

    let answerBox = [];

    for (let i = 0; i < this.tempoMusics.length; i++) {
      // 选项卡属性确定，index是ABCD选项，value是音符或是答案，label是序号，往往和index一样，isCorrect选项是是否是正确答案
      answerBox.push({
        index: indexBox[i],
        value: indexBox[i],
        label: indexBox[i],
        isCorrect: this.answerIndex == i,
      });
    }

    this.answerBox = answerBox;
  
  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
  }
  rateChange(e) { // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this)
  }

}
