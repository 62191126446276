<template>

    <div class="knowledge-base-box">

        <div class="image-area">
            <t-image
                @click="getImage"
                class="image "
                src="https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/knowledge/keys-name-senior.jpg"
                fit="cover"></t-image>
        </div>

        <div class="text-area">
            升降号是用于指示音符的升高或降低的符号。
            <br><br>
            在音乐中，升降号通常位于音符前面，以指示该音符应该比其原始音高更高或更低。常见的升降号包括升号（#）、降号（b）和还原号（♮）。
            <br><br>
            升号（#）：升号位于音符前面时，表示该音符应该比原来的音高升高半音。
            降号（b）：降号位于音符前面时，表示该音符应该比原来的音高降低半音。
            还原号（♮）：还原号位于音符前面时，表示该音符应该恢复到原来的音高，即取消之前的升降变化。
            <br><br>
            这些升降号的使用可以改变音乐作品中的旋律、和声和节奏，为音乐增添了复杂性和表现力。

        </div>
        
    </div>
    <t-image-viewer
      v-model:visible="isOpenImgPreViewer"
      :images="[PreImgSrc]"
      @close="isOpenImgPreViewer=false"
    >
    </t-image-viewer>

</template>

<script>
export default {
    data(){
        return {
            PreImgSrc: '',
            isOpenImgPreViewer: false
        }
    },
    methods: {
        getImage(e){
            this.PreImgSrc = e.srcElement.currentSrc;
            this.isOpenImgPreViewer = true
        }
    }
}
</script>

<style lang="less" scoped>
@import url(./style/knowledgeBase.less);
</style>