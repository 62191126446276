<template>
    <div id="musc-theory-test-content-box">

        <div class="music-theory-test-question">

            <div class="question-box">

                <QuestionIntro :info="TestPaper"></QuestionIntro>

                <div class="question-content">

                    <div class="question-tigan">{{ TestPaper.question_num + '，' + TestPaper.questionIntro.questionTitle }}
                    </div>

                    <div class="audio-box">
                        <div><t-icon :name="TestPaper.isReady ? (TestPaper.isPlaying ? 'pause' : 'play') : 'loading'" :size="TestPaper.isReady ? '40' : '16'" @click="audioPlayer"></t-icon></div>
                        <div id="audio"></div>
                    </div>

                    <FontOptionsCard :info="TestPaper"></FontOptionsCard>
                    
                </div>

                <ScoreBox :info="TestPaper"></ScoreBox>

            </div>

        </div>

    </div>
</template>


<script>
import { theme_color } from "@/tools/MusicTheoryTools";
import QuestionIntro from "@/components/MusicTheoryComponents/components/QuestionIntro"
import ScoreBox from '@/components/MusicTheoryComponents/components/ScoreBox.vue';
import FontOptionsCard from "@/components/MusicTheoryComponents/components/FontOptionsCard.vue";

export default {
    name: "questionsPage",
    components: {
        QuestionIntro,
        ScoreBox,
        FontOptionsCard 
    },
    props: ['info'],
    watch: {
        info: {
            handler(n){
                this.TestPaper = n 
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            TestPaper: null,
            theme_color: theme_color,
        }
    },
    mounted() {
        this.TestPaper.InitQuestion()
    },
    methods: {
        answerChange(e) {
            this.TestPaper.answer = e;
        },
        audioPlayer(){
            this.TestPaper.audioPlayer()
        }
    }
}
</script>

<style lang="less" scoped>
@import url('/src/style/root.less');
@import url('/src/style/music_theory.less');


.audio-box {
    width: 800px;
    margin: var(--pc-margin) 0; 
    box-sizing: border-box;
    height: 120px;
    display: flex;
    align-items: center;

    #audio {
        width: 600px;
    }
}




</style>