import MusicTheoryHome from "@/pages/MusicTheoryPages/MusicTheoryHome/MusicTheoryHome.vue"
import Register from "@/pages/register/register.vue"
import Advertisement from "@/pages/advertisement/advertisement.vue"
import AudioClear from "@/pages/technology/AudioClear/AudioClear.vue"
import VocalClear from "@/pages/technology/AudioClear/components/VocalClear.vue"
import VocalClearLogo from "@/pages/technology/AudioClear/components/VocalClearLogo.vue"
import Studio from '@/pages/studio/studio.vue'
// 网页导航
export const optionPages = [
    {
        path: '/audioClear',
        name: 'audioClear',
        content: 'AI人声处理',
        redirect: '/audioClear/voiceClearIndex',
        component: AudioClear,
        children: [
            {
                path: 'voiceClear',
                name: 'voiceClear',
                content: 'AI人声处理工作站',
                component: VocalClear
            },
            {
                path: 'voiceClearIndex',
                name: 'voiceClearIndex',
                content: 'AI人声处理',
                component:  VocalClearLogo
            }
        ]
    },
    {
        path: '/musicTheoryHome',
        name: 'musicTheoryHome',
        content: '音乐基础知识学习',
        component: MusicTheoryHome
    }
]
export const homePages = [
    {
        path: '/index/AIMusic',
        name: 'AIMusic',
        content: 'AI音乐',
        component: Advertisement
    },
    {
        path: '/index/studio',
        name: 'studio',
        content: '音乐制作',
        component: Studio
    },
    {
        path: '/index/register',
        name: 'register',
        content: '登录页面',
        component: Register
    },
]
