<template>
  <div class="music-theory-header-box">
    <t-head-menu v-model="activatePage" theme="light" @change="changeHandler">

      <!-- <t-menu-item v-for="item, index in pages" :value="item.path" :key="index">
        <t-badge v-if="item.isRequiredPaid && !isMusicTheoryMember" count="会员" :offset="[20, 5]" color="#82175A" shape="round">
          <span class="heater-text"
            :style="{ color: item.isActived ? theme_color : '', weight: item.isActived ? 500 : '' }">{{ item.content
            }}</span>
        </t-badge>

        <span v-else :style="{ color: item.isActived ? theme_color : '', weight: item.isActived ? 500 : '' }">{{
          item.content }}</span>
      </t-menu-item> -->

      <template #operations>
        <t-button style="border-radius: 5px;" variant="text" shape="square" @click="isOpenPiano = true">
          <template #icon><t-icon name="piano" /></template>
        </t-button>
        <t-button style="width: 120px;" variant="text" shape="square">
          在线教师
          <template #icon><t-icon name="service" /></template>
        </t-button>

        <UserAvatar></UserAvatar>
      </template>
    </t-head-menu>
    <KnowledgeBase :isOpen="isOpenPiano" info="piano" @close="isOpenPiano = false"></KnowledgeBase>

  </div>
</template>

<script>
import { userstore } from "@/store/userinfo.js"
import KnowledgeBase from "@/components/MusicTheoryComponents/KnowledgeBox/KnowledgeBase.vue"
import UserAvatar from "../UserCenter/UserAvatar.vue"
import { MusicTheoryExaminationPages } from "@/router/musicTheoryRouterPages"
export default {
  name: 'HeaderPage',
  data() {
    return {
      activatePage: '/',
      pages: MusicTheoryExaminationPages,
      store: userstore(),
      userinfo: null,
      isOpenPiano: false,
      isMusicTheoryMember: false
    }
  },
  async mounted() {
    await this.store.updateUserInfo()
  },
  components: {
    KnowledgeBase,
    UserAvatar
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.activatePage = to.path
      }
    },
    'store.userinfo': {
      handler(n) {
        this.userinfo = n
      },
      deep: true,
      immediate: true
    },
    'store.isMusicTheoryMember': {
      handler(n) {
        this.isMusicTheoryMember = n
      },
      immediate: true
    }
  },
  methods: {
    changeHandler(e) { // 路由跳转
      this.$router.push(e)
    }
  }
}
</script>

<style lang="less" scoped>

.music-theory-header-box {
  box-sizing: border-box;
  width: 100%;
  background: none;

  .t-head-menu {
    background: none;

    .heater-text {
      margin: 20px;
    }

    .t-menu__item:hover:not(.t-is-active):not(.t-is-opened):not(.t-is-disabled) {
      background-color: var(--theme-color) !important;
      border: none;
      outline: none;

      .heater-text {
        color: #ffffff;
      }
    }
  }

}




.t-menu__item.t-is-active,
.t-is-active {
  color: var(--theme-color);
  background: none;
}

.t-menu__item.t-is-active:active {
  background: none !important;
}

.t-menu__operations {
  margin-right: 56px;
  background: none;
  transition: none;

  .t-button {
    margin-left: 8px;
    outline: none;
    border: none;
  }

  .t-button:hover {
    color: #ffffff !important;
    background-color: var(--theme-color) !important;
    border: none;
    border-radius: 5px;
    outline: none;
  }

  .t-button:active {
    background: none !important;
    outline: none;
    border: none;
  }
}

.t-menu--dark .t-menu__item {
  color: var(--background-color);
  background: none;
}
</style>