<template>
    <div class="audio-player-box" :style="{ display: isOpenAudioPlayer ? 'flex' : 'none' }">

        <t-popup placement="top" show-arrow destroy-on-close>
            <p>{{ audioInfo.name }}</p>
            <template #content>
                <div @click="closeAudioPlayer" style="display: flex; align-items: center; cursor: pointer;">
                    <p>关闭播放器</p> <t-icon name="close" size="20"></t-icon>
                </div>
            </template>
        </t-popup>

        <div class="audio-box">
            <t-icon v-if="isReady" style="color: #82175a;" :name="isPlaying ? 'pause' : 'play'" size="36"
                @click="audioPlayer"></t-icon>
            <t-icon v-else style="color: #82175a;" :name="'loading'" size="24"></t-icon>
        </div>
        <div id="audiowaves" style="width: 340px; height: 60px;"></div>
    </div>


</template>

<script>
import { audioPlayer } from '@/store/audioplayer'
import { baseUrl } from '@/tools/tool'
export default {
    data() {
        return {
            isPlaying: false,
            audioContext: null,
            store: audioPlayer(),
            isReady: false,
            isOpenAudioPlayer: false,
            wavesurfer: null,
            audioInfo: {}
        }
    },
    watch: {
        'store.audioInfo': {
            handler(audio) {
                this.audioInfo = audio
                this.isReady = false
                document.getElementById('audiowaves').innerHTML = null
                audioPlayer().makeWavesAudio('audiowaves', baseUrl + '/' + audio.url, this.getWaves)
            },
            deep: true
        },
        'store.isOpenAudioPlayer': {
            handler(isOpen) {
                this.isOpenAudioPlayer = isOpen
            },
            deep: true
        },
    },
    methods: {
        audioControler(audio) {
            console.log(audio)
        },
        getWaves(wavesurfer) {
            this.wavesurfer = wavesurfer;
            wavesurfer.on('ready', () => {
                this.isReady = true
                this.wavesurfer.play()
            });
            // 监听播放状态
            wavesurfer.on('play', () => {
                this.isPlaying = true
            });

            wavesurfer.on('pause', () => {
                this.isPlaying = false
            });
        },
        audioPlayer() {
            if (this.wavesurfer.isPlaying()) {
                this.wavesurfer.pause()
            } else {
                this.wavesurfer.play()

            }
        },
        closeAudioPlayer() {
            if (this.wavesurfer) {
                this.wavesurfer.destroy();
            }
            audioPlayer().isOpenAudioPlayer = false
        }
    }
}
</script>

<style lang="less" scoped>
@import url('/src/style/root.less');

.audio-player-box {
    width: 480px;
    height: 60px;
    margin: auto;
    box-sizing: border-box;
    align-items: center;
    z-index: 100;

    .audio-box {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;



    }
}
</style>