<template>
    <div class="question-intro" v-if="TestPaper.questionIntro">

        <div class="question-intro-head"> 
            {{ TestPaper.questionIntro.title ? TestPaper.questionIntro.title : '' }}
        </div>
        <div class="question-intro-description">
            {{ TestPaper.questionIntro.description }}
        </div>

        <!-- 难度等级切换 -->
        <DifficultyRate :info="TestPaper"></DifficultyRate>
    </div>
</template>


<script>
/* eslint-disable */
import DifficultyRate from "./DifficultyRate.vue";
export default {
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            TestPaper: null,
        }
    },
    components: {
        DifficultyRate,
    },
}
</script>
<style lang="less">


.question-intro {
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .question-intro-head {
        font-size: var(--pc-font-size-title) !important;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .question-intro-description {
        font-size: var(--pc-font-size-normal) !important;
    }

}
</style>