<template>
  <t-aside class="app-aside">
    <t-menu
      expandMutex
      theme="light"
      :value="activatedUrl"
      class="asider-container"
      style="background: none"
    >
      <template #logo>
        <img
          width="40"
          class="logo"
          :src="logo_src"
          alt="logo"
          @click="linkTo('/')"
          style="cursor: pointer"
        />
      </template>

      <t-submenu v-for="(item, index) in menus" :value="index" :key="index">
        <template #title>
          <t-badge
            v-if="item.isRequiredPaid && !isMusicTheoryMember"
            count="会员"
            :offset="[20, 2]"
            color="#82175A"
            shape="round"
          >
            <span class="aside-name">{{ item.name }}</span>
          </t-badge>
          <span v-else class="aside-name">{{ item.name }}</span>
        </template>

        <t-menu-item
          v-for="i in item.contents"
          :key="i.path"
          :value="i.menuValue"
          @click="handleOptionClick(i)"
        >
          <span
            :style="{
              color: i.isActived ? theme_color : '',
              weight: i.isActived ? 500 : '',
            }"
            >{{ i.content }}</span
          >
        </t-menu-item>
      </t-submenu>
    </t-menu>
  </t-aside>

  <OrderDialog></OrderDialog>
</template>

<script>
/* eslint-disable */
import { userstore } from "@/store/userinfo";
import { logo_src, theme_color } from "@/tools/MusicTheoryTools";
import { educationPagesSide } from "@/router/_musicTheoryRouterPages";
import OrderDialog from "@/pages/shop/OrderDialog.vue";
export default {
  name: "AsiderPage",
  data() {
    return {
      logo_src: logo_src, // 头部logo
      activatedUrl: "/",
      menus: null,
      theme_color: theme_color,
      userstore: userstore(),
      isMusicTheoryMember: false
    };
  },
  watch: {
    "userstore.isMusicTheoryMember": {
       immediate: true,
       handler(n){
        this.isMusicTheoryMember = n
       }
    }
  },
  mounted() {
    this.activatedUrl = this.$route.path;
    this.makeMenus();
    this.linkTo(this.activatedUrl);
  },
  components: {
    OrderDialog,
  },
  methods: {
    linkTo(path) {
      this.activatedUrl = path;
      this.$router.push(path);
      for (let i = 0; i < this.menus.length; i++) {
        for (let j = 0; j < this.menus[i].contents.length; j++) {
          if (this.menus[i].contents[j].path == path) {
            this.menus[i].contents[j].isActived = true;
          } else {
            this.menus[i].contents[j].isActived = false;
          }
        }
      }
    },
    makeMenus() {
      let menu = [];
      for (let i in educationPagesSide) {
        menu.push(educationPagesSide[i]);
        menu[menu.length - 1].title = i;
      }
      this.menus = menu.map((item) => {
        return {
          title: item.title,
          name: item.menuType,
          contents: item.pages,
          isRequiredPaid: item.isRequiredPaid ? true : false,
        };
      });
    },
    handleOptionClick(info) {
      this.linkTo(info.path);
    }
  },
};
</script>

<style lang="less" scoped>
.app-aside {
  max-height: 100vh;
  box-sizing: border-box;
  background-color: var(--background-color);
  overflow: hidden;
}
.t-menu__item:hover {
  background: none !important;
  color: #111111;
}

.t-is-active {
  color: var(--theme-color) !important;
  background: none !important;
}

.t-is-active:not(.t-is-opened) .t-icon {
  color: var(--theme-color) !important;
}
</style>
