<template>
  <div :style="{ width: width, height: height }" class="login-box">
    <vueWxlogin v-if="code == null" :appid="appid" :scope="scope" :redirect_uri="redirect_uri"></vueWxlogin>
  </div>
</template>

<script>
/* eslint-disable */
import vueWxlogin from "./components/wxLogin/vueWxlogin.vue";
import { storageWatch, get } from "@/tools/tool";
import { userstore } from "@/store/userinfo";
import { Project } from "@/config";

export default {
  name: "registerLogin",
  props: {
    width: {
      type: String,
      default: "100vw",
    },
    height: {
      type: String,
      default: "100vh",
    },
  },
  components: {
    vueWxlogin,
  },
  data() {
    return {
      appid: "wxc6ad6ad40d66fa8f",
      scope: "snsapi_login",
      redirect_uri: Project.WxRegisterRedirectUrl,
      code: null,
    };
  },
  async mounted() {
    try {
      let code = this.$route.query.code;

      if (code) {
        storageWatch(); // 监听本地localstorage
        const accessInfo = await get("/web/wxLogin", "code=" + code); // 进行登录，请求用户信息和token

        localStorage.setItem("duoyintoken", accessInfo.token); // 进行本地存储
        localStorage.setItem(
          "duoyinuserinfo",
          JSON.stringify(accessInfo.userinfo)
        );
        userstore().updateUserInfo(); // 更新store中的userinfo

        if (path == "/index/register") {
          this.$router.push("/index");
        } else {
          this.$router.go(-2);
        }
      }
    } catch (error) {
      this.$router.push("/index");
    }
  },
};
</script>

<style lang="less">
.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // background-color: var(--background-color);
}
</style>
