<template>

    <div class="technology-header">
        <div>
            <img height="60" style="cursor: pointer;" @click="changeHandler('/')"
                src="https://www.duoyinchina.com/assits/images/logo.png" alt="logo" />
        </div>
        <div>
            <UserAvatar></UserAvatar>
        </div>
    </div>

</template>


<script>
import UserAvatar from '@/components/UserCenter/UserAvatar.vue';

export default {
    name: 'TechnologyPage',
    data() {
        return {
            audioInfo: {},
        }
    },
    components: {
        UserAvatar,
    },
    methods: {
        changeHandler() {
            this.$router.push('/')
        }
    }
}

</script>



<style scoped>
.technology-header {
    height: 100px;
    padding: 20px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>