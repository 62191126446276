<template>
  <t-dialog
    v-model:visible="isOpenDialog"
    :confirmBtn="null"
    :cancelBtn="null"
    @close="getClose"
  >
    <template #body>
      <t-list>
        <t-list-item  v-for="(item, index) in orderList" :key="index">
          <t-list-item-meta style="text-align: left !important;"
            :image="item.primaryImage"
            :title="item.description"
            :description="'订单编号：' + item.order_id + ' 订单时间：' + formatDate(item.pay_time)"
          />
        </t-list-item>
      </t-list>


      <t-list></t-list>
    </template>
  </t-dialog>
</template>

<script>
/* eslint-disable */
import { userstore } from "@/store/userinfo";
import { formatDate } from "@/tools/tool";

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenDialog: false,
      orderList: [],
    };
  },
  watch: {
    isOpen: {
      handler(n) {
        this.isOpenDialog = n;
        const orders = userstore().ordersList.filter((item) => {
          // 查验是否已经购买了相关订单，并在有效期限内
          if (item.status == "SUCCESS") {
            return item;
          }
        });

        if (orders.length > 0) {
          this.orderList = orders;
        }
      },
      immediate: true,
    },
  },
  methods: {
    getClose() {
      this.$emit("ternOff");
    },
    formatDate(e) {
      return formatDate(e);
    },
  },
};
</script>