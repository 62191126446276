/* eslint-disable */ 
import axios from 'axios';
import qs from 'qs';
import { MessagePlugin } from 'tdesign-vue-next';
import { Project } from '@/config';
export const baseUrl = Project.baseUrl

const instance = axios.create({
    timeout: 12000,
    baseURL: baseUrl
});

export const get = (url, params=null) => {
    return new Promise((resolve, reject) => {

        const path = params!=null ? url + '?' + params : url;

        instance.get(path)
        .then(response => {
            resolve(response.data);
        })
        .catch((err) => {
            reject(err);
        });
    })
};

export const post = (url, params, message = null) => {

    if(message){
        MessagePlugin.loading(message)
    }

    return new Promise((resolve, reject) => {
        instance.post(
            url, 
            qs.stringify({params: JSON.stringify({...params})}), 
            { 
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
                }
            }
        )
        .then(response => {
            if(message){
                MessagePlugin.closeAll()
            }
            resolve(response.data);
        })
        .catch((err) => {
            reject(err);
        });
    })
};
