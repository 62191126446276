<template>
    <router-view></router-view>
    <RegisterDialog></RegisterDialog>
</template>

<script>
import RegisterDialog from './pages/register/RegisterDialog.vue';
export default {
  name: 'App',
  components: {
    RegisterDialog
  }
}
</script>
