<template>
    <Header></Header>
    <div id="index-container" style="width: 100vw; height: 100vh;">
      <router-view></router-view>
    </div>
</template>

<script>
import Header from "@/components/header.vue";

export default {
  name: 'indexPage',
  components: {
    Header
  },
}
</script>

<style scoped>
@import url("/src/style/root.less");


  #index-container {
    background-color: var(--background-color);
  }
</style>