/* eslint-disable */
import { TestAssets } from "./assits";

export class QuestionIntervalSenior {

  constructor(div, page) {
    TestAssets.questionInfoInit(this, div, page)
  }
  InitQuestion() {

    this.questionIntro = this.questionIntros.find((item) => item.value == this.level); // 根据难度等级选择题目描述

    this.notesBox = this.questionIntro.notesBox;

    TestAssets.InitVariate(this)

    this.makeAnswerBox(); // 生成答题卡

  }
  // 参数1，音符，参数2，音名还是唱名
  makeAnswerBox() {
    let ask = 'name'

    const interval = printInterval(this)

    if (this.page == 'NoteInterval12Low') {
      ask = 'call'
    } else {
      ask = 'name'
    }

    // 返回4个音符，包括一个正确答案的
    const indexBox = ['A', 'B', 'C', 'D'];
    const optionBox = generateRandomInterval4(interval);
    let answerBox = [];
    for (let i = 0; i < optionBox.length; i++) {
      answerBox.push({
        index: indexBox[i],
        value: optionBox[i],
        label: `${indexBox[i]}, 曲谱上音符之间的音程是：${TestAssets.intervalNameBox.find(item => optionBox[i] == item.value)[ask]}`,
        isCorrect: interval == optionBox[i]
      });

    }

    this.answerBox = answerBox;
  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
  }
  rateChange(e) { // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this)
  }
}

// 返回4个音程的答案
const generateRandomInterval4 = (input) => {
  if (input < 1 || input > 12) {
    throw new Error("输入的数字必须在2到8之间");
  }
  const box = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const numbers = box.filter(item => item !== input);

  const result = [];

  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * numbers.length);
    result.push(numbers[randomIndex]);
    numbers.splice(randomIndex, 1);
  }

  result.push(input);
  return result.sort(() => Math.random() - 0.5);
}

const generateIntervalByLevel = (that) => { // 根据页面确定音程
  let distance = 11

  if (that.page == 'NoteInterval12Low') {
    distance = that.level + 1
  }

  const box = Array.from({ length: distance }, (_, i) => i + 1)

  return box[Math.floor(Math.random() * box.length)]; // 返回随机生成的音程
}

const printInterval = (that) => {
  const note = TestAssets.makeNote(that.notesBox); // 生成一个随机音符，也就是要考的音高

  const interval = generateIntervalByLevel(that) // 返回随机生成的音程

  const diration = ['up', 'down']

  const noteslist = TestAssets.notesBox[diration[Math.floor(Math.random() * diration.length)]]

  const note1 = noteslist[noteslist.indexOf(note) + interval]

  TestAssets.NotePrinterSenior(that.div, [
    {
      note: [note],
      duration: '4',
      count: {
        num: 4,
      }
    },
    {
      note: [note1],
      duration: '4',
      count: {
        num: 4,
      }
    }
  ]);

  return interval
}