<template>
  <div class="knowledge-base-box">
    <div class="image-area">
      <t-image
        @click="getImage"
        class="image"
        src="https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/knowledge/modeName.jpg"
        fit="cover"
        style="width: 590px; background-color: #ffffff"
      ></t-image>
    </div>
  </div>

  <t-image-viewer
    v-model:visible="isOpenImgPreViewer"
    :images="[PreImgSrc]"
    @close="isOpenImgPreViewer = false"
  >
  </t-image-viewer>
</template>

<script>
export default {
  data() {
    return {
      PreImgSrc: "",
      isOpenImgPreViewer: false,
    };
  },
  methods: {
    getImage(e) {
      this.PreImgSrc = e.srcElement.currentSrc;
      this.isOpenImgPreViewer = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import url(./style/knowledgeBase.less);
</style>
