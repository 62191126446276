<template>
    <div class="question-intro-rate" style="display: flex; align-items: center">
        难度等级：
        <t-rate style="margin-left: 6px" :value="Number(TestPaper.level)" size="24" variant="filled"
            :color="[theme_color, '#82175a11']" :count="TestPaper.questionIntros.length" @change="rateChange" />
    </div>
</template>


<script>
import { theme_color } from "@/tools/MusicTheoryTools";
export default {
    props: ['info'],
    watch: {
        info: {
            handler(n) {
                this.TestPaper = n
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            TestPaper: null,
            theme_color: theme_color
        }
    },
    methods: {
        rateChange(e) {
            this.TestPaper.rateChange(e);
        }
    }
}
</script>
<style lang="less">

.question-intro-rate {
    font-size: var(--pc-font-size-normal);
}

</style>