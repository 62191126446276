<template>
    <CommonKnowledgeAudio :info="info"></CommonKnowledgeAudio>
</template>


<script>
import CommonKnowledgeAudio from '@/components/MusicTheoryComponents/CommonKnowledgeAudio.vue';
import { AreaMusic } from "@/tools/MusicTheoryTools";

export default {
    components: {
        CommonKnowledgeAudio
    },
    data() {
        return {
            info: new AreaMusic('audio', 'SeniorListeningForeignMusic', '央院中级听辩西方流行音乐')
        }
    }
}
</script>

