/* eslint-disable */ 

import MusicFoundation from "@/pages/MusicTheoryPages/music_foundation/musicFoundation.vue"

import { educationPages, RouterComponents  } from "./_musicTheoryRouterPages" // 辅助代码写作页面

export const musicEducationPages = educationPages.map( (item, index)=>{  // 生成音乐基础知识学习考试的页面
    const element = RouterComponents.find(i => i.name == item.name) // 根据组件名称找到组件，加载到页面 

    return {
        ...educationPages[index],
        component: element.component// 根据页面名称选择相关组件
    }
    
})

export const music_theory_pages = [
    {
        path: '/musicFoundation',
        content: '音乐基础知识考试',
        component: MusicFoundation,
        children: musicEducationPages
    },
]

export const MusicTheoryExaminationPages = [
    {
      content: '中央音乐学院音基初级模拟考试',
      path: '/CCOM_MusicTheoryExaminationPrimary',
      isRequiredPaid: true,
      isActived: false
    },
    {
      content: '中国音乐学院音基模拟考试',
      path: '/CNOM_MusicTheoryExaminationPrimary',
      isRequiredPaid: true,
      isActived: false
    },
    {
      content: '音乐专业视唱练耳模拟考试',
      path: '/MusicProfessionalSolfeggioTrainingMockExamination',
      isRequiredPaid: true,
      isActived: false
    },
  ]