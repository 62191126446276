<template>
  <div class="adver">
    <MusicTheoryStudy></MusicTheoryStudy>
  </div>
</template>
<script>
import MusicTheoryStudy from './components/MusicTheoryStudy.vue';
export default {
  name: "HomePage",
  components: {
    MusicTheoryStudy
  }
};
</script>

<style lang="less" scoped>
.adver {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
  background: none;
  background-color: var(--background-color);
}
</style>
