<template>
    <BaseExamPaper :info="info"></BaseExamPaper >
</template>

<script>
/* eslint-disable */
import BaseExamPaper from '@/components/MusicTheoryComponents/BaseExamPaper.vue';
import { BaseExam } from '@/tools/modules/MusicTheoryModules/baseExam';

export default {
    components: {
        BaseExamPaper
    },
    data() {
        return {
            info: new BaseExam('SeniorPitchNameRecognition', '央院中级音高识别音名')
        }
    }
}
</script>
