/* eslint-disable */
import { TestAssets } from "./assits";

export class NoteRecognition { // 音符：音名，唱名识别
  
  constructor(div, page, askfor) {
    TestAssets.questionInfoInit(this, div, page, askfor)
  }

  InitQuestion() {
    
    this.questionIntro = this.questionIntros.find((item) => item.value == this.level); // 根据难度等级选择题目描述
    this.makeAnswerBox(); // 生成答题卡
  }
  // 参数1，音符，参数2，音名还是唱名
  makeAnswerBox() {

    this.notesBox = makeNotesBoxByPage(this)  // 根据页面返回音符盒子
    
    TestAssets.InitVariate(this)

    const note = TestAssets.makeNote(this.notesBox); // 生成一个随机音符，也就是要考的音高

    TestAssets.NotePrinterSenior(this.div, [{ // 打印
      note: [note],
      duration: '4',
      count: {
        num: 4,
      }
    }])
  
    const indexBox = ["A", "B", "C", "D"];
    const optionBox = TestAssets.makeRandomNotes(note, this.notesBox);

    let answerBox = [];
    let name = {};
    const askfor = this.askfor

    for (let i = 0; i < optionBox.length; i++) {
      name = TestAssets.singBox.filter(
        (item) => item.name.indexOf(optionBox[i][0]) !== -1
      )[0];

      const option = optionBox[i];

      answerBox.push({
        index: indexBox[i],
        value: optionBox[i],
        label: `${indexBox[i]}, ${makeModeSign(askfor, option)} ${name[askfor]}`,
        isCorrect: note == optionBox[i],
      });

    }
    this.answerBox = answerBox;
  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this)
  }
  finished() {
    TestAssets.finished(this.score, this.level, this.questionIntros.length, this)
  }
  rateChange(e) { // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this)
  }
}

// 根据页面返回音符盒子
const makeNotesBoxByPage = (that) => {
   // 解决各个页面的音符盒子的体系
   if(that.page == 'NotePitchTwelveEqualLawName' || that.page == 'NotePitchTwelveEqualLawSing'){
    return [...that.questionIntro.notesBox, ...that.questionIntro.notesBox.map(item=>{
      const regex = /\d+/g; // 数字筛选的正则表达式
      const result = item.match(regex)[0]; // 返回音符里的数字
      const name = item.replace(result, '')
      return name + ( Math.random() < 0.5 ? '#' : 'b') + result;
    })].sort(() => Math.random() - 0.5);

  }else {
    return that.questionIntro.notesBox; // 当前级别需要的音符数组
  }
}
// 回答选项的文字
const makeModeSign = (askfor, option)=> {
  return `曲谱上音符的${askfor == "sing" 
                        ? "“唱名”是：" + (option.indexOf('#') !== -1 ? '升' : (option.indexOf('b') !== -1 ? '降' : '')) 
                        : "”音名“是：" + (option.indexOf('#') !== -1 ? '#' : (option.indexOf('b') !== -1 ? 'b' : ''))
                    }`
}