<template>
  <div class="knowledge-base-box">
    <div class="image-area">
      <t-image
        @click="getImage" 
        class="image"
        src="https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/knowledge/TonicSolfa.jpg"
        fit="cover"
      ></t-image>
    </div>
    <div class="text-area">
      音级是音乐理论中的一个基本概念，它与音乐的调式密切相关。为了更好地理解这一概念，我们可以通过具体的例子来分析。
      <br /><br />
      首先，让我们以C大调为例。在C大调中，中央C被指定为一级音，也就是我们通常所说的“do”。
      按照这个逻辑，D音（C键右侧的白键）是二级音，唱作“re”；
      E音（D音右侧的白键）是三级音，唱作“mi”；
      F音（E音右侧的白键）是四级音，唱作“fa”，
      依此类推直到G音（F音右侧的白键），作为五级音，唱作“sol”。
      <br /><br />
      现在，如果我们将调式改为D大调，情况会有所不同。在D大调中，D音（C键右侧的白键）成为新的一级音，在D大调中也唱作“do”。相应地，E音（D键右侧的白键）变为二级音，唱作“re”；F#音（E音右侧白键F上的黑键）是三级音，唱作“mi”；G音（F#音右侧的白键）是四级音，唱作“fa”，以此类推。
      <br /><br />
      通过这个例子，我们可以看到，随着调式的改变，每个音级的对应音名也会改变，而唱名不变。这种变化反映了音乐调式的基本特性：不同的调式有着相同的音级排列，而音阶则是根据这些音级排列来确定的。
      为了进一步说明这一点，我们可以考虑另一个例子：G大调。在G大调中，G音（即中央C上从左到右数的第五个白键）是一级音，唱作“do”。接着，A音（G键右侧的白键）是二级音，唱作“re”；B音（A音右侧的白键）是三级音，唱作“mi”；C音（B音右侧的白键）是四级音，唱作“fa”，依此类推。
      <br /><br />
      上图是以F大调进行详解：
    </div>
  </div>
  <t-image-viewer
      v-model:visible="isOpenImgPreViewer"
      :images="[PreImgSrc]"
      @close="isOpenImgPreViewer=false"
    >
    </t-image-viewer>

</template>

<script>
export default {
    data(){
        return {
            PreImgSrc: '',
            isOpenImgPreViewer: false
        }
    },
    methods: {
        getImage(e){
            this.PreImgSrc = e.srcElement.currentSrc;
            this.isOpenImgPreViewer = true
        }
    }
}
</script>

<style lang="less" scoped>
@import url("./style/knowledgeBase.less");
</style>
