<template>
  <div class="header-box">
    <t-head-menu v-model="activatePage" theme="light" @change="changeHandler">
      <template #logo>
        <img height="40" style="cursor: pointer;" @click="changeHandler('/')"
          src="https://www.duoyinchina.com/assits/images/logo.png" alt="logo" />
      </template>

      <t-menu-item style="border-radius: 5px; " v-for="item, index in pages" :value="item.path" :key="index"> {{
        item.content
        }} </t-menu-item>

      <template #operations>
        <t-button style="border-radius: 5px;" variant="text" shape="square" @click="isOpenPiano=true">
          <template #icon><t-icon name="piano" /></template>
        </t-button>
        <t-button style="border-radius: 5px; margin-right: 20px;" variant="text" shape="square">
          <template #icon><t-icon name="service" /></template>
        </t-button>

        <UserAvatar></UserAvatar>

      </template>
    </t-head-menu>

    <KnowledgeBase :isOpen="isOpenPiano" info="piano" @close="isOpenPiano=false"></KnowledgeBase>

  </div>
</template>

<script>
import KnowledgeBase from "@/components/MusicTheoryComponents/KnowledgeBox/KnowledgeBase.vue"
import UserAvatar from "./UserCenter/UserAvatar.vue";
import { MessagePlugin } from "tdesign-vue-next";


export default {
  name: 'HeaderPage',
  data() {
    return {
      activatePage: '/',
      pages: [
        {
          content: '音乐制作',
          path: '/index/studio'
        },
        {
          content: '音乐基础知识学习',
          path: '/musicTheoryHome'
        }
      ],
      isOpenPiano: false
    }
  },
  components: {
    KnowledgeBase,
    UserAvatar
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.activatePage = to.path
      }
    }
  },
  methods: {
    changeHandler(e) { // 路由跳转

      if(e !== '/composer' && e !== '/audioClear'){
        this.$router.push(e)
      }else{
        MessagePlugin.error('页面建设中..')
      }
     
    }
  }
}
</script>

<style lang="less" scoped>

.header-box {
  box-sizing: border-box;
  width: 100%;
  padding: 0 56px;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;

  .t-menu__logo img {
  margin-left: 0 !important;
}

.t-head-menu {
  background: none;
  transition: none;
}

.t-menu__item:hover:not(.t-is-active):not(.t-is-opened):not(.t-is-disabled) {
  color: var(--background-color);
  background-color: var(--theme-color) !important;
  border: none;
  outline: none;
}

.t-menu__item.t-is-active,
.t-is-active {
  color: var(--theme-color);
  background: none;
}

.t-menu__item.t-is-active:active {
  background: none !important;
}

.t-menu__operations {
  margin-right: 56px;
  background: none;
  transition: none;

  .t-button {
    margin-left: 8px;
    outline: none;
    border: none;
  }

  .t-button:hover {
    color: var(--background-color);
    background-color: var(--theme-color) !important;
    border: none;
    border-radius: 5px;
    outline: none;
  }

  .t-button:active {
    background: none !important;
    outline: none;
    border: none;
  }
}

.t-menu--dark .t-menu__item {
  color: var(--background-color);
  background: none;
}

}



</style>