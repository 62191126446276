<template>
  <swiper :modules="modules" :slides-per-view="5" :space-between="16" loop
    :autoplay="{
      delay: 5000, // 自动播放间隔时间（毫秒）
      disableOnInteraction: false, // 用户交互后是否停止自动播放
      waitForTransition: false, // 不等待过渡效果完成就切换到下一张幻灯片
      setWrapperSize: true,
      cssMode: true, // 启用CSS模式
    }" @swiper="onSwiper" @slideChange="onSlideChange">
    >
    <swiper-slide v-for="(item, index) in musicList" :key="index">
      <MusicCard :music="item"></MusicCard>
    </swiper-slide>

  </swiper>
</template>

<script>
/* eslint-disable */
import { get } from '@/tools/tool';
import MusicCard from './MusicCard.vue';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay'

// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide,
    MusicCard
  },
  data() {
    return {
      musicList: []
    }
  },
  setup() {
    const onSwiper = (swiper) => {
   
    };
    const onSlideChange = () => {
  
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  async mounted() {
    const res = await get('/web/studio/musicList')
    this.musicList = res.filter(item => item.img_src != '')
  }
};
</script>
