import { userstore } from "@/store/userinfo";

export const storageWatch = () => { // 监听localstorage的数据变化
  window.addEventListener('storage', function (event) {
    if (event.key == 'duoyinuserinfo') {
      const store = userstore()
      store.updateUserInfo()
    }
  });
}

