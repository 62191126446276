<template>
  <t-dialog header="您还未登录" v-model:visible="isOpenRegister" :confirmBtn="null" :cancelBtn='null' @close="close" placement="center" width="520px" style="height:520px">
    <template #body>
      <register width="100%" height="520px"></register>
    </template>
    </t-dialog>
</template>

<script>
/* eslint-disable */ 
import register from './register.vue'
import { userstore } from '@/store/userinfo';

export default {
  components: {
    register
  },
  data() {
    return {
      userStore: userstore(),
      isOpenRegister: false,
    }
  },
  watch: {
    "userStore.isOpenRegister": {
      immediate: true,
      handler(n) {
        this.isOpenRegister = n
      }
    },

  },
  methods: {
    close(){
      userstore().isOpenRegister = false
    }
  }


}
</script>

<style lang="less">



</style>
