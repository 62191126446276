<template >
    <div class="voice-clear-box">
        <div class="voice-clear-introduce">
            <div class="vc-title">
                多音-AI人声净化器
            </div>
            <div class="vc-content">
                一键智能去除录音文件中的混响与底噪，还原人声高清音质。
            </div>
            <img src="@/assets/waves-icon2.png" style="width: 460px; margin-bottom: 48px;" />

            <UploadFile @return="recodeAudioInfo"></UploadFile>
        </div>
    </div>
</template>

<script>
import UploadFile from '@/components/UploadFile'
export default {
    components: {
        UploadFile
    },
    methods: {
        recodeAudioInfo(url) {
            localStorage.setItem('voiceAudioUrl', url)
            this.$router.push('/audioClear/voiceClear')
        },
    }
}
</script>


<style lang="less" scoped>
.voice-clear-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f6f6f6;

    .voice-clear-introduce {
        width: 460px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .vc-title {
            font-size: 48px;
            font-weight: 600;
            margin-bottom: 24px;
        }

        .vc-content {
            font-size: 17px;
            margin-bottom: 24px;
        }
    }

}
</style>