/* eslint-disable */
import { baseUrl } from "@/tools/tool";
const regex = /\d+/g; // 数字筛选的正则表达式

export const makeNotesBox = () => {
  // 根据函数生成的音符数组对象，按钢琴的88个键制作的，属性up代表升号音符群，down代表降号音符群
  const n = ["C", "D", "E", "F", "G", "A", "B"];
  let notes = [];
  let notesb = [];

  for (let i = 0; i < 8; i++) {
    for (let j = 0; j < n.length; j++) {
      if (n[j].indexOf("F") == -1 && n[j].indexOf("C") == -1) {
        notesb.push(n[j] + "b" + i);
      }

      notes.push(n[j] + i);

      notesb.push(n[j] + i);

      if (n[j].indexOf("E") == -1 && n[j].indexOf("B") == -1) {
        notes.push(n[j] + "#" + i);
      }
    }
  }

  notes = notes.slice(9);
  notesb = notesb.slice(9);

  return {
    up: notes,
    down: notesb,
  };
};
export const singBox = [
  // 唱名盒子
  {
    name: "C",
    sing: "Do",
  },

  {
    name: "D",
    sing: "Re",
  },
  {
    name: "E",
    sing: "Mi",
  },
  {
    name: "F",
    sing: "Fa",
  },
  {
    name: "G",
    sing: "Sol",
  },
  {
    name: "A",
    sing: "La",
  },
  {
    name: "B",
    sing: "Si",
  },
  {
    name: "C#",
    sing: "升Do",
  },

  {
    name: "D#",
    sing: "升Re",
  },
  {
    name: "E#",
    sing: "升E",
  },
  {
    name: "F#",
    sing: "升Fa",
  },
  {
    name: "G#",
    sing: "升Sol",
  },
  {
    name: "A#",
    sing: "升La",
  },
  {
    name: "B#",
    sing: "升Si",
  },
  {
    name: "Cb",
    sing: "降Do",
  },
  {
    name: "Db",
    sing: "降Re",
  },
  {
    name: "Eb",
    sing: "降Mi",
  },
  {
    name: "Fb",
    sing: "降Fa",
  },
  {
    name: "Gb",
    sing: "降Sol",
  },
  {
    name: "Ab",
    sing: "降La",
  },
  {
    name: "Bb",
    sing: "降Si",
  },
];
export const intervalNameBox = [
  // 音程的名称与距离
  {
    name: "小二度",
    value: 1,
    call: "半音",
  },
  {
    name: "大二度",
    value: 2,
    call: "全音",
  },
  {
    name: "小三度",
    value: 3,
    call: "一个全音加一个半音",
  },
  {
    name: "大三度",
    value: 4,
    call: "两个全音",
  },
  {
    name: "纯四度",
    value: 5,
    call: "两个全音加一个半音",
  },
  {
    name: "增三减五",
    value: 6,
    call: "三个全音",
  },
  {
    name: "纯五度",
    value: 7,
    call: "三个全音加一个半音",
  },
  {
    name: "小六度",
    value: 8,
    call: "三个全音加两个半音",
  },
  {
    name: "大六度",
    value: 9,
    call: "四个全音加一个半音",
  },
  {
    name: "小七度",
    value: 10,
    call: "四个全音加两个半音",
  },
  {
    name: "大七度",
    value: 11,
    call: "五个全音加一个半音",
  },
  {
    name: "八度",
    value: 12,
    call: "五个全音加两个半音",
  },
];
export const TempoMarks = {
  primary: [
    {
      name: "Lento",
      call: "慢板",
      time: "50-56",
    },
    {
      name: "Andante",
      call: "行板",
      time: "60-69",
    },
    {
      name: "Allegro",
      call: "快板",
    },
    {
      name: "Presto",
      call: "急板",
    },
  ],
  senior: [
    {
      name: "Largo",
      call: "广板",
      time: "40-50",
    },
    {
      name: "Lento",
      call: "慢板",
      time: "50-56",
    },
    {
      name: "Adagio",
      call: "柔板",
      time: "56-60",
    },
    {
      name: "Andante",
      call: "行板",
      time: "60-69",
    },
    {
      name: "Andantiono",
      call: "小行板",
      time: "69-88",
    },
    {
      name: "Moderato",
      call: "中板",
      time: "88-96",
    },
    {
      name: "Allegretto",
      call: "小快板",
      time: "96-110",
    },
    {
      name: "Allegro",
      call: "快板",
      time: "110-160",
    },
    {
      name: "Presto",
      call: "急板",
      time: "160-192",
    },
  ],
};
export const makeNote = (noteBox) => {
  // 根据输入的参数音符数组，返回一个随机音符；参数类型 ['F/1', 'G/1', 'A/1', 'B/1', 'C/1']
  const randomIndex = Math.floor(Math.random() * noteBox.length);
  const note = noteBox[randomIndex];
  return note;
};
const sortNotes = (notes) => {
  // 将传入的音符盒子进行排序，按音符从低到高排序 [C2, D3, E3, G4]

  const notesList = makeNotesBox(); // 利用音符在数组中的位置排序

  notes.sort((a, b) => {
    if (a.indexOf("b") !== -1) {
      return notesList.down.indexOf(a) > notesList.down.indexOf(b) ? 1 : -1;
    } else {
      return notesList.up.indexOf(a) > notesList.up.indexOf(b) ? 1 : -1;
    }
  });

  return notes;
};
export const makeNotes = (noteBox, num, isSenior = false) => {

  // 参数1: array 音符盒子；参数2: Number返回4组，每组包裹几个音符； ！！！写的太不容易了，注意保留
  let box = new Set();
  let i = 0;
  while (box.size < 4 || i > 100) {
    i++;
    let notes = sortNotes(
      Array.from({ length: num }, (_, i) => {
        return noteBox[Math.floor(Math.random() * noteBox.length)];
      })
    );

    notes = shuffleArray(notes)

    if (num !== 2) {
      box.add(JSON.stringify(notes));
    } else {
      if (notes[0] != notes[1]) {
        box.add(JSON.stringify(notes));
      }
    }
  }

  box = [...box].map((item) => JSON.parse(item))

  return box;
};
export const makeRandomNotes = (note, notesBox, length = 4) => {
  // 制作选项卡需要的音符，一般是一个要求的音符，和三个不一样的随机音符; 参数1: 答案音符，参数2:包括答案音符的数组

  const notesList = notesBox // 返回全部不是参数音符的音符
    .filter((item) => item.split("")[0] !== note.split("")[0])
    .sort(() => Math.random() - 0.5);

  const uniqValues = notesList.filter((item, index) => {
    // 去重过滤,只保留不同音名的音符数组
    return (
      notesList.findIndex((v) => v.split("")[0] === item.split("")[0]) === index
    );
  });

  let result = Array.from(new Set([note, ...uniqValues])).slice(0, length); // 和参数音符组成一个新书组，并返回加参数音符的前4个

  result = result.sort(() => Math.random() - 0.5); // 随机打乱顺序，形成选项卡音符

  return result;
};
const tempoNotesBox = (notesBox, level, isRandom) => {
  // 节奏类音符
  const randomNote = (box) => {
    let note = box[Math.floor(Math.random() * box.length)];
    const n = note.match(regex);
    return note.replace(n, "") + "/" + n;
  };

  let level1 = [
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "2",
      },
    ],
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "4",
      },
    ],
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "4",
      },
    ],
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "8",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "8",
      },
    ],
  ];

  let level2 = [
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
    ],
  ];

  let level3 = [
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "8",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
    ],
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "8",
      },
    ],
  ];

  let level4 = [
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "8",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
    ],
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "8d",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
    ],
  ];

  let level5 = [
    [
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "16",
      },
      {
        keys: isRandom ? [randomNote(notesBox)] : ["C/4"],
        duration: "8d",
      },
    ],
  ];

  let box = [];

  if (level == 1) {
    box = level1;
  } else if (level == 2) {
    box = [...level1, ...level2];
  } else if (level == 3) {
    box = [...level1, ...level2, ...level3];
  } else if (level == 4) {
    box = [...level1, ...level2, ...level3, ...level4];
  } else if (level == 5) {
    box = [...level1, ...level2, ...level3, ...level4, ...level5];
  }

  return box.sort(() => Math.random() - 0.5);
};
/*
  randomTempoNotesBox
 
  功能：返回的音符可以直接用于NoteTempoPrinter()函数用来打印，和playTempo()函数进行播放
  参数1: 音符的数组盒子；
  参数2: 当前级别，级别越高节奏越复杂1-5
  参数3: 需要返回几个数组；
  return：返回可用NoteTempoPrinter打印和用playTempo播放的的vexflow音符数组
*/
export const randomTempoNotesBox = (
  notesBox,
  level = 1,
  num = 4,
  isRandom = false
) => {
  // 参数1: 数组盒子；参数2:需要返回几个数组；return：返回可打印的vexflow音符数组

  const tempoBox = tempoNotesBox(notesBox, level, isRandom); // 返回节奏片段的音符盒子，都是随机的音符

  let count = 0; // 统计一共需要的拍数

  let b = []; // 装节奏音符的盒子，并最终返回出去

  for (let i = 0; i < tempoBox.length; i++) {
    // 让每小节的音符时长总数等于4拍，写了我一天，操！！！2024年3月27日

    let n = 0; // 统计每个片段的节拍时值

    for (let j = 0; j < tempoBox[i].length; j++) {
      // 关键步骤

      let item = tempoBox[i][j].duration; // 返回每个音符的节拍名

      let speed = 4;

      // 计算节拍的关键步骤
      if (item.indexOf("d") !== -1) {
        n =
          n +
          (((speed / Number(item.replace("d", ""))) *
            Number(item.replace("d", ""))) /
            Number(item.replace("d", ""))) *
            (1 + 0.5);
      } else if (item.indexOf("r") !== -1) {
        n = n + speed / Number(item.replace("r", ""));
      } else {
        n = n + speed / Number(item);
      }
    }

    count = count + n; // 核算总节拍时值

    if (count == num) {
      b.push(tempoBox[i]); // 如果节拍够了，则打断循环
      break;
    } else if (count > 4) {
      randomTempoNotesBox(notesBox, level, num); // 最经典的一步，如果超出节拍，则全部重新计算，太妙了！！！
    } else {
      b.push(tempoBox[i]);
    }
  }

  return b;
};
export const generate_unique_rhythms = (that) => {
  // 生成4组随机并不重复的节奏乐谱
  let setBox = new Set(); // 为了避免重复的出现

  while (setBox.size < 4) {
    // 生成4组随机并不重复的节奏乐谱
    const a = JSON.stringify(
      randomTempoNotesBox(that.notesBox, that.level, 4, that.isRandom)
    );
    setBox.add(a);
  }

  let tempoMusics = [...setBox];

  return tempoMusics.map((item) => JSON.parse(item)); // 可使用的节奏乐谱
};
// 参数1:一个固定要求的元素。返回：在数组中随机抽取3个元素和固定要求的元素，不重复
export const getRandomFour = (key, box) => {
  let setBox = new Set();
  setBox.add(JSON.stringify(key));

  while (setBox.size < 4) {
    // 生成4组随机并不重复的节奏乐谱
    setBox.add(JSON.stringify(box[Math.floor(Math.random() * box.length)]));
  }

  let tempoMusics = [...setBox];

  return tempoMusics.map((item) => JSON.parse(item));
};
// 随机打乱数组
export const shuffleArray = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};
export const get8notesfromArray = (arr, length=8) => { // 在音符数组里截取连续的几个音符
  
  if (arr.length <= length) {
    return arr;
  }

  const startIndex = Math.floor(Math.random() * (arr.length - (length - 1)));
  return arr.slice(startIndex, startIndex + length);
};

export const doesNotContainAny = (param1, param2) => { // 字符传的模糊查询，参数1是要查询的字符传，参数2是要对比的字符串数据数组，返回true/false
    const regex = new RegExp(param2.join('|'), 'i');
    return regex.test(param1);
  }